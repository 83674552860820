export function truncateHTMLWithStructure(html, maxLength) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html.replace(
    /<(a)([^>]*)>/g,
    '<$1$2 style="margin-top: 0px; padding-top: 0px;">'
  );
  tempDiv.innerHTML =  tempDiv.innerHTML.replace(
    /<(ul|ol)([^>]*)>/g,
    '<$1$2 style="margin-top: 0px; padding: 10px; padding-top: 0px; padding-left: 14px; padding-bottom: 0px; margin-bottom: 0px;">'
  );

  let totalLength = 0;
  const truncateNode = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textLength = node.textContent.length;
      if (totalLength + textLength > maxLength) {
        node.textContent =
          node.textContent.slice(0, maxLength - totalLength) + '...';
        totalLength = maxLength;
      } else {
        totalLength += textLength;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        if (totalLength >= maxLength) {
          // Remove extra child nodes
          while (node.childNodes.length > i) {
            node.removeChild(node.lastChild);
          }
          break;
        }
        truncateNode(node.childNodes[i]);
      }
    }
  };
  if (maxLength) truncateNode(tempDiv);
  return tempDiv.innerHTML;
}

export function truncateText(text, maxLength, suffix = '...') {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + suffix;
  }
  return text;
}
