import React, { Component, Fragment, Modal, Share, createRef } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import {
  Typography,
  Select,
  FormControl,
  InputBase,
  MenuItem,
  MenuList,
  Grid,
  Divider,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  ListItemAvatar,
  ListItem,
  Paper,
  List,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  colors,
  Drawer,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AssistantIcon from "@material-ui/icons/Assistant";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import QRCode from "react-qr-code";
import { getStorageItem } from "../../../../common/localStorage";
import { apiData } from "../../../../common/common-types";
import { stockAPI } from "../../../../common/axiosInstance";

import down_arrow from "../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import shapeCircular from "../../../../assets/icons/referFriend/shapeCircular.png";
import giftIcon from "../../../../assets/icons/referFriend/giftIcon.svg";
import formIcon from "../../../../assets/icons/referFriend/formIcon.svg";
import shareIconwhite from "../../../../assets/icons/referFriend/shareIconwhite.svg";
import farwardArrow from "../../../../assets/icons/referFriend/farwardArrow.svg";
import filter from "../../../../assets/icons/redeem/filter.svg";

import { makeStyles } from "@material-ui/core/styles";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import zIndex from "@material-ui/core/styles/zIndex";
import PrivilegeFilter from "./PrivilegeFilter";
import { truncateHTMLWithStructure, truncateText } from "../../../../utils";

const styles = (theme) => {
  return {
    // wrapper: {
    //   objectFit: "contain",
    //   backgroundColor: theme.palette.globalBackground,
    //   paddingBottom: "100px",
    //   [theme.breakpoints.down("641")]: {
    //     paddingBottom: "50px",
    //   },
    //   [theme.breakpoints.down("481")]: {
    //     paddingBottom: "50px",
    //   },
    //   [theme.breakpoints.down("421")]: {
    //     paddingBottom: "50px",
    //   },
    //   [theme.breakpoints.down("401")]: {
    //     paddingBottom: "50px",
    //   },
    //   [theme.breakpoints.down("381")]: {
    //     paddingBottom: "50px",
    //   },
    //   [theme.breakpoints.down("361")]: {
    //     paddingBottom: "50px",
    //   },
    //   [theme.breakpoints.down("321")]: {
    //     paddingBottom: "50px",
    //   },
    // },
    // multiBox: {
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "space-between",
    // },
    // WrapperBox: {
    //   width: "90%",
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
    // iconWrapper: {
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   width: "65px",
    //   height: "65px",
    //   borderRadius: "100%",
    //   backgroundColor: theme.palette.primary.main,
    //   marginTop: "20px",
    // },
    // subLabel: {
    //   height: "85px",
    //   width: "145px",
    //   fontWeight: "500",
    //   fontStretch: "normal",
    //   fontStyle: "normal",
    //   letterSpacing: "normal",
    //   textAlign: "center",
    //   marginTop: "10px",
    //   color: theme.palette.typography.texts.color,
    //   fontSize: "16px",
    //   [theme.breakpoints.down("641")]: {
    //     fontSize: "14px",
    //     width: "100px",
    //   },
    //   [theme.breakpoints.down("481")]: {
    //     fontSize: "12px",
    //     width: "100px",
    //   },
    //   [theme.breakpoints.down("421")]: {
    //     fontSize: "11.5px",
    //     width: "100px",
    //   },
    //   [theme.breakpoints.down("401")]: {
    //     fontSize: "11px",
    //     width: "100px",
    //   },
    //   [theme.breakpoints.down("381")]: {
    //     fontSize: "10.5px",
    //     width: "100px",
    //   },
    //   [theme.breakpoints.down("361")]: {
    //     fontSize: "10px",
    //     width: "100px",
    //   },
    //   [theme.breakpoints.down("321")]: {
    //     fontSize: "8px",
    //     width: "100px",
    //   },
    // },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    // btn: {
    //   width: "90%",
    //   height: "43px",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   backgroundColor: theme.palette.primary.main,
    // },
    // btnText: {
    //   textAlign: "center",
    //   textTransform: "uppercase",
    //   fontWeight: "bold",
    //   color: theme.palette.dashBoardheadText.color,
    //   fontSize: "22px",
    //   [theme.breakpoints.down("641")]: {
    //     fontSize: "16px",
    //   },
    //   [theme.breakpoints.down("481")]: {
    //     fontSize: "16px",
    //   },
    //   [theme.breakpoints.down("421")]: {
    //     fontSize: "12px",
    //   },
    //   [theme.breakpoints.down("401")]: {
    //     fontSize: "12px",
    //   },
    //   [theme.breakpoints.down("381")]: {
    //     fontSize: "12px",
    //   },
    //   [theme.breakpoints.down("361")]: {
    //     fontSize: "12px",
    //   },
    //   [theme.breakpoints.down("321")]: {
    //     fontSize: "12px",
    //   },
    // },
    // bottomLink: {
    //   padding: "1px",
    //   paddingBottom: "20px",
    //   display: "flex",
    //   justifyContent: "center",
    // },
    // bottomLinkText: {
    //   marginTop: "15px",
    //   fontSize: "14px",
    //   textDecoration: "underline",
    //   color: theme.palette.primary.main,
    //   [theme.breakpoints.down("641")]: {
    //     fontSize: "10px",
    //   },
    //   [theme.breakpoints.down("481")]: {
    //     fontSize: "10px",
    //   },
    //   [theme.breakpoints.down("421")]: {
    //     fontSize: "10px",
    //   },
    //   [theme.breakpoints.down("401")]: {
    //     fontSize: "10px",
    //   },
    //   [theme.breakpoints.down("381")]: {
    //     fontSize: "10px",
    //   },
    //   [theme.breakpoints.down("361")]: {
    //     fontSize: "10px",
    //   },
    //   [theme.breakpoints.down("321")]: {
    //     fontSize: "10px",
    //   },
    // },
    // middleTextContainer: {
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   flexDirection: "column",
    //   width: "99%",
    //   paddingBottom: "10px",
    //   paddingTop: "10px",
    // },
    referFriendText: {
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "17px",
      },
    },
    GetRegisterText: {
      fontSize: "20px",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      [theme.breakpoints.down("641")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    shortcutsLinkIcon: {
      width: "50px",
      height: "50px",
      position: "absolute",
      [theme.breakpoints.down("641")]: {
        width: "43px",
        height: "43px",
      },
      [theme.breakpoints.down("481")]: {
        width: "33px",
        height: "33px",
      },
      [theme.breakpoints.down("381")]: {
        width: "28px",
        height: "28px",
      },
      [theme.breakpoints.down("321")]: {
        width: "28px",
        height: "28px",
      },
    },
    referFrienTextOberoi: {},
    giftIconCss: {
      backgroundColor: theme.palette.referFriend.colorWhite,
      " -webkit-mask-image": `url(.${giftIcon})`,
      "mask-image": `url(.${giftIcon})`,
      mask: `url(.${giftIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${giftIcon}) no-repeat center / contain`,
    },
    shareIconCss: {
      backgroundColor: theme.palette.referFriend.colorWhite,
      " -webkit-mask-image": `url(.${shareIconwhite})`,
      "mask-image": `url(.${shareIconwhite})`,
      mask: `url(.${shareIconwhite}) no-repeat center / contain`,
      "-webkit-mask": `url(.${shareIconwhite}) no-repeat center / contain`,
    },
    formIconCss: {
      backgroundColor: theme.palette.referFriend.colorWhite,
      " -webkit-mask-image": `url(.${formIcon})`,
      "mask-image": `url(.${formIcon})`,
      mask: `url(.${formIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${formIcon}) no-repeat center / contain`,
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "5px",
      paddingTop: "10px",
    },
    middleCircleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    outerCicleCss: {
      width: "200px",
      height: "200px",
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${shapeCircular})`,
      "mask-image": `url(.${shapeCircular})`,
      mask: `url(.${shapeCircular}) no-repeat center / contain`,
      "-webkit-mask": `url(.${shapeCircular}) no-repeat center / contain`,
    },
    outerCicleLinkIcon: {
      width: "150px",
      height: "150px",
      transition: ".12s",
      "-webkit-mask-repeat": "no-repeat",
      "-webkit-mask-size": "contain",
      "mask-size ": "contain",
      [theme.breakpoints.down("641")]: {
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.down("481")]: {
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.down("381")]: {
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.down("321")]: {
        width: "150px",
        height: "150px",
      },
    },
    innercircleCss: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.referFriend.colorWhite,
      position: "absolute",
      borderRadius: "50%",
      width: "120px",
      height: "120px",
    },
    innerCirclebg: {
      width: "90px",
      height: "90px",
      backgroundColor: theme.palette.primary.main,
      padding: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
    },
    innerCircleIcon: {
      backgroundColor: theme.palette.referFriend.colorWhite,
      " -webkit-mask-image": `url(.${giftIcon})`,
      "mask-image": `url(.${giftIcon})`,
      mask: `url(.${giftIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${giftIcon}) no-repeat center / contain`,
    },
    InnerIconlinks: {
      width: "100px",
      height: "100px",
      position: "absolute",
      [theme.breakpoints.down("641")]: {
        width: "43px",
        height: "43px",
      },
      [theme.breakpoints.down("481")]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.down("381")]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.down("321")]: {
        width: "40px",
        height: "40px",
      },
    },
    imageContainer1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "5px",
      paddingTop: "10px",
      backgroundColor: theme.palette.primary.main,
    },
    referFriendText1: {
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "center",
      color: theme.palette.referFriend.colorWhite,
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "17px",
      },
    },
    middleTextContainer1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "99%",
      paddingBottom: "1px",
      paddingTop: "10px",
    },
    outerCicleCss1: {
      width: "230px",
      height: "230px",
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${shapeCircular})`,
      "mask-image": `url(.${shapeCircular})`,
      mask: `url(.${shapeCircular}) no-repeat center / contain`,
      "-webkit-mask": `url(.${shapeCircular}) no-repeat center / contain`,
    },
    GetRegisterText1: {
      fontSize: "20px",
      textAlign: "center",
      color: theme.palette.referFriend.colorWhite,
      [theme.breakpoints.down("641")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    iconbg: {
      backgroundColor: theme.palette.referFriend.colorWhite,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "59px",
      height: "59px",
      borderRadius: "100%",
    },
    giftIconCss1: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${giftIcon})`,
      "mask-image": `url(.${giftIcon})`,
      mask: `url(.${giftIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${giftIcon}) no-repeat center / contain`,
    },
    shareIconCss1: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${shareIconwhite})`,
      "mask-image": `url(.${shareIconwhite})`,
      mask: `url(.${shareIconwhite}) no-repeat center / contain`,
      "-webkit-mask": `url(.${shareIconwhite}) no-repeat center / contain`,
    },
    formIconCss1: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${formIcon})`,
      "mask-image": `url(.${formIcon})`,
      mask: `url(.${formIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${formIcon}) no-repeat center / contain`,
    },
    ticketConatiner: {
      paddingTop: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ticketIConCss: {
      height: "100px",
      width: "250px",
    },
    textConatiner: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      flexDirection: "column",
      paddingBottom: "5px",
    },
    getReferText: {
      margin: "0px 0px 2px 2px",
      fontSize: "12px",
      fontWeight: "normal",
      color: theme.palette.typography.texts.sellingPriceColor,
    },
    ReferalCodeText: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    iconWrapper1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100px",
      height: "100px",
      borderRadius: "100%",
      backgroundColor: " linear-gradient(to top, #f5f5f5, #ffffff)",
      marginTop: "20px",
      boxShadow: " 0px -3px 3px 0 rgba(0, 0, 0, 0.1);",
      [theme.breakpoints.down(641)]: {
        width: "100px",
        height: "100px",
      },
      [theme.breakpoints.down(481)]: {
        width: "100px",
        height: "100px",
      },
      [theme.breakpoints.down(421)]: {
        width: "70px",
        height: "70px",
      },
      [theme.breakpoints.down(381)]: {
        width: "70px",
        height: "70px",
      },
      [theme.breakpoints.down(361)]: {
        width: "70px",
        height: "70px",
      },
      [theme.breakpoints.down(321)]: {
        width: "70px",
        height: "70px",
      },
    },
    badgeCss: {
      width: "30px",
      height: "30px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "-100px",
      [theme.breakpoints.down(641)]: {
        width: "25px",
        height: "25px",
        marginTop: "-70px",
      },
      [theme.breakpoints.down(481)]: {
        width: "25px",
        height: "25px",
        marginTop: "-70px",
      },
      [theme.breakpoints.down(421)]: {
        width: "23px",
        height: "23px",
        marginTop: "-60px",
      },
      [theme.breakpoints.down(381)]: {
        width: "23px",
        height: "23px",
        marginTop: "-65px",
      },
      [theme.breakpoints.down(361)]: {
        width: "23px",
        height: "23px",
        marginTop: "-65px",
      },
      [theme.breakpoints.down(321)]: {
        width: "23px",
        height: "23px",
        marginTop: "-65px",
      },
    },
    badgeText: {
      color: theme.palette.referFriend.colorWhite,
      fontSize: "13px",
    },
    imageContainer2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "5px",
      paddingTop: "10px",
      backgroundColor: "#0081c1",
    },
    iconbg1: {
      border: "solid 2px #0074ad",
      backgroundColor: theme.palette.referFriend.colorWhite,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "59px",
      height: "59px",
      borderRadius: "100%",
    },
    arrowConatiner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "100px",
    },
    arrowbg: {
      height: "25px",
      width: "25px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    LineCss: {
      position: "absolute",
      height: "2px",
      width: "40px",
      backgroundColor: theme.palette.primary.main,
    },
    farwardArrowCSS: {
      zIndex: 100,
      height: "15px",
      width: "20px",
      position: "absolute",
      backgroundColor: theme.palette.referFriend.colorWhite,
      " -webkit-mask-image": `url(.${farwardArrow})`,
      "mask-image": `url(.${farwardArrow})`,
      mask: `url(.${farwardArrow}) no-repeat center / contain`,
      "-webkit-mask": `url(.${farwardArrow}) no-repeat center / contain`,
    },
    root: {
      [theme.breakpoints.down(641)]: {
        padding: "28px",
      },
      [theme.breakpoints.down(481)]: {
        padding: "21px",
      },
      [theme.breakpoints.down(381)]: {
        padding: "15px",
      },
      [theme.breakpoints.down(321)]: {
        padding: "13px",
      },
    },
    okBtn: {
      ...theme.palette.typography.doneButtons,
      marginBottom: 0,
      fontSize: "22px",
      height: "92px",
      lineHeight: "18px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "18px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "16px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        height: "54px",
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "13px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    subHeading: {
      fontSize: "18px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.64",
      letterSpacing: "normal",
      color: theme.palette.typography.texts.color,
      // textAlign: 'center',
      marginBottom: "30px",
      [theme.breakpoints.down(767)]: {
        lineHeight: "1.64",
        fontSize: "16px",
        marginBottom: "28px",
      },
      [theme.breakpoints.down(481)]: {
        lineHeight: "1.5",
        fontSize: "14px",
        marginBottom: "21px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(381)]: {
        lineHeight: "1.5",
        fontSize: "13px",
        marginBottom: "15px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.6",
        marginBottom: "13px",
      },
    },

    conatiner: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.globalBackground,
      marginBottom: "10px",
    },
    titleTextConatiner: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    titleCss: {
      marginTop: "20px",
      marginBottom: "5px",
      width: "80%",
      fontFamily: " Montserrat",
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "1.5",
      letterSpacing: "normal",
      color: theme.palette.primary.main,
      textAlign: "center",
      [theme.breakpoints.down(767)]: {
        fontSize: "16px",
      },
    },
    titleCss1: {
      marginTop: "20px",
      marginBottom: "5px",
      width: "80%",
      fontFamily: " Montserrat",
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "1.5",
      letterSpacing: "normal",
      color: theme.palette.referFriend.text,
      textAlign: "center",
      [theme.breakpoints.down(767)]: {
        fontSize: "16px",
      },
    },
    subHeaderText: {
      fontFamily: " Montserrat",
      fontSize: "18px",
      fontStretch: "normal",
      fontStyle: " normal",
      lineHeight: "1.67",
      letterSpacing: "normal",
      textAlign: "center",
      margin: "0 15px 20px",
      [theme.breakpoints.down(767)]: {
        fontSize: "13px",
      },
    },
    stepsContainer: {
      display: "flex",
      margin: "0 10px 5px",
    },
    text2Css: {
      paddingLeft: "1px",
      margin: "2px 0px 0px 5px",
      fontFamily: " Montserrat",
      fontSize: "18px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.64",
      letterSpacing: "normal",
      color: theme.palette.typography.texts.color,
      // textAlign: 'center',
      marginBottom: "30px",
      [theme.breakpoints.down(767)]: {
        lineHeight: "1.64",
        fontSize: "13px",
        marginBottom: "28px",
      },
      [theme.breakpoints.down(481)]: {
        lineHeight: "1.5",
        fontSize: "13px",
        marginBottom: "21px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(381)]: {
        lineHeight: "1.5",
        fontSize: "13px",
        marginBottom: "15px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(320)]: {
        fontSize: "12px",
        lineHeight: "1.6",
        marginBottom: "13px",
      },
    },
    indexBG: {
      display: "flex",
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
      width: "30px",
      height: "30px",
      fontWeight: "bold",
      borderRadius: "100%",
      fontSize: "12px",
      textAlign: "center",
      justifyContent: "center",
      color: "white",
    },
    ticketIConCss1: {
      height: "100px",
      width: "250px",
    },
    referGiftCss: {
      width: "269px",
      height: "269px",
      objectFit: "contain",
      [theme.breakpoints.down(767)]: {
        width: "220px",
        height: "220px",
      },
    },
    howItWorkConatiner: {
      minHeight: "50px",
    },
    ticketConatiner1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    panelMainDiv: {
      display: "flex",
      justifyContent: "space-around",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      // fontSize: theme.typography.pxToRem(15),
      // fontSize: 12,
      color: "#898989",
      // fontWeight: 600,
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      // textAlign: "center",
      // color: theme.palette.typography.texts.color,
      fontSize: "16px",
      padding: "0",
      margin: "0",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
      "& ul": {
        paddingBottom: '0px !important',
        marginBottom: '0px !important',
    },
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      marginBottom: 0,
      fontSize: "18px",
      lineHeight: "18px",
      height: "92px",

      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(361)]: {
        height: "46px",
        fontSize: "12px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    noWrap: {
      whiteSpace: "nowrap",
      marginLeft: "15px",
      lineHeight: "22px",
    },
    textBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    titleBox: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: "600",
      display: "flex",
      marginBottom: "5px",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    bottomBox: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 0 0 10px",
    },
    numBox: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#000",
      marginTop: "8px",
    },
    noteText: {
      fontSize: "14px",
      color: "#898989",
      marginTop: "8px",
    },
    // img: {
    //   display: "inline-block",
    //   objectFit: "contain",
    //   objectPosition: "center",
    //   width: "38px",
    //   height: "24px",
    //   [theme.breakpoints.down(641)]: { width: "36px", height: "22px" },
    //   [theme.breakpoints.down(481)]: { width: "34px", height: "20px" },
    //   [theme.breakpoints.down(381)]: { width: "32px", height: "18px" },
    //   [theme.breakpoints.down(321)]: { width: "30px", height: "16px" },
    // },
    img: {
      // marginRight: "16px",
      display: "inline-block",
      // transition: ".12s",
      // "-webkit-mask-size": "cover",
      // "mask-size ": "cover",
      // objectFit: "contain",
      // objectPosition: "center",
      width: "100%",
      height: "100%",
      marginTop: "10px",
      // height: "30px",
      // [theme.breakpoints.down(641)]: { width: "48px", height: "28px" },
      // [theme.breakpoints.down(481)]: { width: "46px", height: "26px" },
      // [theme.breakpoints.down(381)]: { width: "44px", height: "24px" },
      // [theme.breakpoints.down(321)]: { width: "40px", height: "22px" },
    },
    disabled: {
      opacity: 0.5,
    },
    selectDropdown: {
      zIndex: 999,
    },
    panelDetail: {
      // display: "flex",
      justifyContent: "center",
      // flexDirection: "column",
      alignItems: "center",
      padding: "10px 10px",
      textAlign: "center",
    },
    filterDropdown: {
      zIndex: 1,
      textTransform: "none",
    },
    leftIcon: {
      // backgroundColor: theme.palette.primary.main,
      // color: "#fff",
      // padding: "15px",
      height: "55px",
      width: "70px",
      alignItems: "center",
      display: "flex",
    },
    detail: {
      padding: "0px 0 0 10px",
      display: "flex",
      justifyContent: "space-between",
    },
    panelHeading: {
      color: theme.palette.primary.main,
      marginRight: "10px",
      marginLeft: "5px",
      fontSize: "14px",
    },
    privilegeHeadBox: {
      display: "flex",
      alignItems: "center",
    },
    panelIcon: {
      color: theme.palette.primary.main,
      // verticalAlign: "text-bottom",
    },
    panelBadge: {
      backgroundColor: "#ffd54f",
    },
    validityPeriod: {
      backgroundColor: "#FFF",
      color: theme.palette.primary.main,
      border: `.5px solid ${theme.palette.primary.main}`,
      width: "fit-content",
      marginTop: "5px",
      marginBottom: "2px",
    },
    filter_icon_container: {
      position: "relative",
      padding: 0,
      borderRadius: 0,
      overflow: "auto",
      display: "inline-block",
      height: "100%",
      margin: 0,
    },
    filter_dot: {
      borderRadius: "50%",
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 20,
      backgroundColor: theme.palette.primary.main,
      width: "11px",
      height: "11px",
      [theme.breakpoints.down("xs")]: {
        width: "8px",
        height: "8px",
      },
    },
    icon_container: {
      display: "flex",
      justifyContent: "flex-end",
    },
    filterIconCss: {
      backgroundColor: theme.palette.redeemHeader.iconsColor,
      " -webkit-mask-image": `url(.${filter})`,
      "mask-image": `url(.${filter})`,
      mask: `url(.${filter}) no-repeat center / contain`,
      "-webkit-mask": `url(.${filter}) no-repeat center / contain`,
    },
    cardIcons: {
      width: "22px",
      height: "22px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      marginLeft: "2rem",
      // float: 'right',
      [theme.breakpoints.down("xs")]: {
        width: "16px",
        height: "16px",
        marginLeft: "1rem",
      },
    },
    navButton: {
      backgroundColor: "#3077AC !important", // Adding !important
      opacity: "1 !important", // Adding !important
      transition: "all 0.3s ease",
      "&:hover": {
        backgroundColor: "#3077AC !important", // Adding !important
        opacity: "1 !important", // Adding !important
      },
    },

    tierAvatarRoot: {
      [theme.breakpoints.down("lg")]: {
        Width: "50px",
        Height: "35px",
      },
      [theme.breakpoints.down("sm")]: {
        Width: "50px",
        Height: "35px",
      },
      [theme.breakpoints.down("xs")]: {
        Width: "22px",
        Height: "15px",
      },
    },

    square: {
      width: "100%",
      height: "53px",
      padding: "10px",
      lineHeight: "20px",
      color: "#FFFFFF",
      backgroundColor: "#00abe5",
      float: "left",
      textAlign: "center",
      fontWeight: "bold",
      [theme.breakpoints.down("lg")]: {
        borderRadius: "4px",
      },
      [theme.breakpoints.down("sm")]: {
        borderRadius: "4px",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "4px",
        height: "48px",
      },
      fontSize: "18px",
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
        height: "37px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
        height: "28px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "9px",
        height: "25px",
      },
    },
    Blue: {
      backgroundColor: "var(--blue)",
    },
    Gold: {
      backgroundColor: "var(--gold)",
    },
    Silver: {
      backgroundColor: "var(--silver)",
    },
    Platinum: {
      color: "#a3a3a3",
      backgroundColor: "var(--platinum)",
    },
  };
};

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    fontSize: "20px",
    padding: "10px 24px 10px 32px",
    textAlign: "left",
    backgroundColor: "#fff",
    border: "solid 1px #e4e4e4",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 24px 8px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 24px 6px 16px",
    },
    [theme.breakpoints.down("641")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("481")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("381")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("321")]: {
      fontSize: "12px",
    },
  },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};
// const options = [
//   "This Month",
//   "Last Month",
//   "This Quarterly",
//   "Half Yearly",
//   "This Year",
//   "Last Year",
// ];

const options = [
  { name: "Active", val: 1 },
  { name: "Expired", val: 0 },
];

class Privilege extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      innerExpanded: false,
      pointsSelection: 0,
      selectedIndex: 1,
      privilegeArray: [
        { name: "This Month", val: 1 },
        { name: "Last Month", val: 2 },
        { name: "This Quarterly", val: 3 },
        { name: "Half Yearly", val: 4 },
        { name: "This Year", val: 5 },
        { name: "Last Year", val: 6 },
      ],
      open: false,
      openLocation: false,
      openDetailLocation: false,
      privilegeData: [],
      locationData: [{ id: "all", location_name: "All" }],
      detailLocationData: [{ id: "", location_name: "All" }],
      selectedLocationId: "all",
      selectedDetailLocationId: "",
      privilegeDataByLocation: [],
      selectedDetailLocations: [],
      selectLocId: "",
      filterApplied: false,
      appliedLocationId: "all",
      appliedStatusId: 1,
      activeStep: 0,
    };

    this.anchorRef = createRef();
    this.anchorRefLocation = createRef();
    this.anchorRefDetailLocation = createRef({});
  }

  handleToggle = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  handleClose = (e) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(e.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleToggleLocation = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      openLocation: !prevState.openLocation,
    }));
  };

  handleCloseLocation = (e) => {
    if (
      this.anchorRefLocation.current &&
      this.anchorRefLocation.current.contains(e.target)
    ) {
      return;
    }
    this.setState({ openLocation: false });
  };

  handleToggleDetailLocation = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      openDetailLocation: !prevState.openDetailLocation,
    }));
  };

  handleCloseDetailLocation = (e) => {
    if (
      this.anchorRefDetailLocation.current &&
      this.anchorRefDetailLocation.current.contains(e.target)
    ) {
      return;
    }
    this.setState({ openDetailLocation: false });
  };

  handleMenuItemClick = (e) => {
    let index = e.target.value;
    e.stopPropagation();
    this.setState(
      {
        selectedIndex: index,
        open: false,
        innerExpanded: false,
      }

      // async () => {
      //   const user = JSON.parse(getStorageItem("user"));
      //   const privilegePayload = new FormData();

      //   privilegePayload.append("customer_id", user.id);
      //   privilegePayload.append("platform", apiData.platform);
      //   privilegePayload.append("merchant_id", apiData.merchant_id);
      //   // privilegePayload.append("filter_type", index + 1);
      //   privilegePayload.append("status", options[index]?.val);

      //   const privilegs = await stockAPI(
      //     privilegePayload,
      //     "POST",
      //     "/getCustomerPrivilegeVouchersList"
      //   );

      //   if (this.state.selectedLocationId == "") {
      //     this.setState({
      //       privilegeData: privilegs ? privilegs : {},
      //       privilegeDataByLocation: privilegs ? privilegs : {},
      //     });
      //   } else {
      //     let updatedPrivilegeData = privilegs.filter((data) =>
      //       data.locations.some(
      //         (location) => location.id == this.state.selectedLocationId
      //       )
      //     );
      //     this.setState({
      //       privilegeData: privilegs ? privilegs : {},
      //       privilegeDataByLocation: updatedPrivilegeData
      //         ? updatedPrivilegeData
      //         : {},
      //     });
      //   }
      //   this.props.openLoader(false);
      // }
    );
  };

  handleMenuItemClickLocation = (e) => {
    let id = e.target.value || "";
    e.stopPropagation();
    this.setState(
      {
        selectedLocationId: id,

        openLocation: false,
        innerExpanded: false,
      }

      // async () => {
      //   if (id == "") {
      //     this.setState({
      //       privilegeDataByLocation: this.state.privilegeData
      //         ? this.state.privilegeData
      //         : {},
      //     });
      //   } else {
      //     let updatedPrivilegeData = this.state.privilegeData.filter((data) =>
      //       data.locations.some((location) => location.id == id)
      //     );
      //     this.setState({
      //       privilegeDataByLocation: updatedPrivilegeData
      //         ? updatedPrivilegeData
      //         : {},
      //     });
      //   }

      //   this.props.openLoader(false);
      // }
    );
  };

  handleMenuItemClickDetailLocation = (e) => {
    e.stopPropagation();
    console.log(e.target.value);
    // console.log(e.target.value);
    // this.setState(
    //   {
    //     selectedDetailLocationId: e.target.value,
    //     openDetailLocation: false,
    //   }

    // async () => {
    //   if (id == "") {
    //     this.setState({
    //       privilegeDataByLocation: vouchersData
    //         ? eVouchersData
    //         : {},
    //     });
    //   } else {
    //     let updatedPrivilegeData = this.state.privilegeData.filter((data) =>
    //       data.locations.includes(id)
    //     );
    //     this.setState({
    //       privilegeDataByLocation: updatedPrivilegeData
    //         ? updatedPrivilegeData
    //         : {},
    //     });
    //   }

    //   this.props.openLoader(false);
    // }
    // );
  };

  handleMenuItemClickDetailLocationValue = (e, id) => {
    e.stopPropagation();
    console.log(id);
    // console.log(e.target.value);
    this.setState({
      selectedDetailLocationId: id,
      openDetailLocation: false,
    });
  };

  handleGetEvoucherCode = async (e, orderDetails) => {
    e.stopPropagation();
    // async () => {
    if (orderDetails.location_id == "") {
      // if (this.state.selectedDetailLocationId == "") {
      openAlertBox("Please select specific location", "error");
    } else {
      const user = JSON.parse(getStorageItem("user"));
      const evoucherPayload = new FormData();

      evoucherPayload.append("customer_id", user.id);
      evoucherPayload.append("platform", apiData.platform);
      evoucherPayload.append("merchant_id", apiData.merchant_id);
      evoucherPayload.append("order_details", JSON.stringify(orderDetails));

      const evouchers = await stockAPI(evoucherPayload, "POST", "/placeOrder");
      // this.setState({
      //   eVouchersData: evouchers ? evouchers : {},
      // });

      // user = JSON.parse(getStorageItem("user"));

      if (evouchers) {
        const privilegePayload = new FormData();
        privilegePayload.append("customer_id", user.id);
        privilegePayload.append("platform", apiData.platform);
        privilegePayload.append("merchant_id", apiData.merchant_id);
        // privilegePayload.append("filter_type", this.state.selectedIndex + 1);
        privilegePayload.append("status", this.state.selectedIndex);

        const privilegs = await stockAPI(
          privilegePayload,
          "POST",
          "/getCustomerPrivilegeVouchersList"
        );

        if (privilegs) {
          if (this.state.selectedLocationId == "all") {
            this.setState({
              privilegeData: privilegs ? privilegs : {},
              privilegeDataByLocation: privilegs ? privilegs : {},
            });
          } else {
            let updatedPrivilegeData = privilegs.filter((data) =>
              data.locations.some(
                (location) => location.id == this.state.selectedLocationId
              )
            );
            this.setState({
              privilegeData: privilegs ? privilegs : {},
              privilegeDataByLocation: updatedPrivilegeData
                ? updatedPrivilegeData
                : {},
            });
          }
        }
      }
    }

    this.props.openLoader(false);
    // };
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  handleInnerChange =
    (panel, detailLocationList, locationId) => (event, isExpanded) => {
      this.setState({
        selectLocId: locationId,
        innerExpanded: isExpanded ? panel : false,
        selectedDetailLocationId: detailLocationList[0]?.id,
      });
    };

  handlePrivilegeChange = (e) => {
    const value = e.target.value;
    this.setState({
      pointsSelection: value,
    });
  };

  async componentDidMount() {
    this.props.openLoader(true);

    var user;
    var ptShortName;

    if (getStorageItem("user")) {
      user = JSON.parse(getStorageItem("user"));
      ptShortName = getStorageItem("pointShortName")
        ? getStorageItem("pointShortName")
        : "P";

      const privilegePayload = new FormData();
      privilegePayload.append("customer_id", user.id);
      privilegePayload.append("platform", apiData.platform);
      privilegePayload.append("merchant_id", apiData.merchant_id);
      // privilegePayload.append("filter_type", this.state.selectedIndex + 1);
      privilegePayload.append("status", this.state.selectedIndex);

      const locationPayload = new FormData();
      locationPayload.append("customer_id", user.id);
      locationPayload.append("platform", apiData.platform);
      locationPayload.append("merchant_id", apiData.merchant_id);
      // console.log(user);
      // console.log(privilegePayload);

      const privilegs = await stockAPI(
        privilegePayload,
        "POST",
        "/getCustomerPrivilegeVouchersList"
      );

      const locations = await stockAPI(
        locationPayload,
        "POST",
        "/getStoreLocations"
      );

      // console.log("location",locations);

      if (privilegs && locations?.locations) {
        let locationList = locations?.locations;
        let newLocation = [...this.state.locationData, ...locationList];
        this.setState({
          privilegeData: privilegs ? privilegs : {},
          privilegeDataByLocation: privilegs ? privilegs : {},
          locationData: locations?.locations ? newLocation : {},
          // detailLocationData: locations?.locations ? newLocation : {},
        });

        this.props.openLoader(false);
      } else {
        this.props.openLoader(false);
        // openAlertBox("Oops something went wrong!", "error");
      }
    } else {
      this.props.openLoader(false);
      this.props.logout();
    }
  }

  openFilterDrawer = (e) => {
    e.stopPropagation();
    this.setState({
      filterDrawer: true,
    });
  };

  closeFilterDrawer = () => {
    this.setState({
      filterDrawer: false,
      isLoader: true,
      selectedLocationId: this.state.selectedLocationId,
      openLocation: false,
      innerExpanded: false,
      selectedIndex: this.state.selectedIndex,
    });
  };

  updatedAppliedFilterData = async () => {
    const { selectedIndex, selectedLocationId } = this.state;

    // Stop if no filter selected
    if (selectedIndex === undefined || selectedLocationId === undefined) {
      return;
    }

    this.setState({
      open: false,
      openLocation: false,
      innerExpanded: false,
    });

    // Get user data from storage
    const user = JSON.parse(getStorageItem("user"));
    const privilegePayload = new FormData();

    privilegePayload.append("customer_id", user.id);
    privilegePayload.append("platform", apiData.platform);
    privilegePayload.append("merchant_id", apiData.merchant_id);
    privilegePayload.append("status", selectedIndex);

    // Fetch privileges
    const privilegs = await stockAPI(
      privilegePayload,
      "POST",
      "/getCustomerPrivilegeVouchersList"
    );
    if (privilegs) {
      let updatedPrivilegeData = privilegs;

      if (selectedLocationId !== "all") {
        updatedPrivilegeData = privilegs.filter((data) =>
          data.locations.some((location) => location.id == selectedLocationId)
        );
      }

      // Set state with filtered data
      this.setState({
        privilegeData: privilegs || {},
        privilegeDataByLocation: updatedPrivilegeData || {},
      });
    }

    // Hide loader
    this.props.openLoader(false);
  };

  applyFilter = () => {
    this.setState(
      {
        filterDrawer: false,
        isLoader: true,
        appliedStatusId: this.state?.selectedIndex,
      },
      async () => {
        this.updatedAppliedFilterData();
      }
    );
  };

  resetFilter = () => {
    this.setState(
      {
        filterDrawer: false,
        isLoader: true,
        selectedLocationId: "all",
        openLocation: false,
        innerExpanded: false,
        selectedIndex: 1,
        appliedStatusId: 1,
      },
      async () => {
        this.updatedAppliedFilterData();
      }
    );
  };

  render() {
    const { classes, merchantDetails } = this.props;
    const {
      open,
      selectedIndex,
      locationData,
      detailLocationData,
      selectedLocationId,
      selectedDetailLocationId,
      openLocation,
      openDetailLocation,
    } = this.state;
    // console.log(locationData);
    const userInfo = JSON.parse(getStorageItem("user"));

    return (
      // <div className={classes.root}>
      <div style={{ overflow: "auto" }}>
        {/* <div>
        <Button
          ref={this.anchorRef}
          aria-controls={this.state.open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          Toggle Menu Grow
        </Button>
        <Popper open={this.state.open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                    <MenuItem onClick={(e) => this.handleClose(e, "this-month")}>This Month</MenuItem>
                    <MenuItem onClick={(e) => this.handleClose(e, "last-year")}>Last year</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div> */}

        <Accordion
          expanded={true}
          // onChange={this.handleChange("panel1")}
          className="rewardsWrap"
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="panelDiv"
          >
            <div className={classes.privilegeHeadBox}>
              <div>
                <StarBorderIcon className={classes.panelIcon} />
              </div>
              <div>
                <b className={classes.panelHeading}>
                  Privileges ({this.state.privilegeDataByLocation.length}){" "}
                </b>
              </div>
              <div>
                {" "}
                <b>
                  <ListItemAvatar classes={{ root: classes.tierAvatarRoot }}>
                    <Avatar
                      variant="square"
                      className={`${classes.square} ${
                        classes[
                          this.props.merchantDetailsResponse.customerDetails
                            .current_tier_name
                        ]
                      }`}
                    >
                      {this.props.merchantDetailsResponse &&
                        this.props.merchantDetailsResponse.customerDetails &&
                        this.props.merchantDetailsResponse.customerDetails
                          .current_tier_name}
                    </Avatar>
                  </ListItemAvatar>
                  {/* <Chip
                    size="small"
                    label={`${
                      this.props.merchantDetailsResponse &&
                      this.props.merchantDetailsResponse.customerDetails &&
                      this.props.merchantDetailsResponse.customerDetails
                        .current_tier_name
                    }`}
                    className={classes.panelBadge}
                  /> */}
                </b>
              </div>
            </div>
            {/* filter */}
            <div>
              {this?.state?.filterApplied ? (
                <p
                  onClick={(e) => this?.openFilterDrawer(e)}
                  className={classes.filter_icon_container}
                >
                  <span className={classes.filter_dot} />
                  <span
                    className={classes.cardIcons + " " + classes.filterIconCss}
                  />
                </p>
              ) : (
                <span
                  className={classes.filterIconCss + " " + classes.cardIcons}
                  onClick={(e) => this?.openFilterDrawer(e)}
                />
              )}
              {this.state.filterDrawer && (
                <Drawer
                  anchor={"right"}
                  open={this.state.filterDrawer}
                  onClose={this.closeFilterDrawer}
                  variant="temporary"
                >
                  <PrivilegeFilter
                    selectedLocationId={selectedLocationId}
                    allLocations={locationData}
                    onChangeHandler={this.handleMenuItemClickLocation}
                    handleApplyBtn={this.applyFilter}
                    handleResetBtn={this.resetFilter}
                    handleMenuItemClickLocation={
                      this.handleMenuItemClickLocation
                    }
                    status={options}
                    selectedStatus={selectedIndex}
                    handleStatusMenuItemClick={this.handleMenuItemClick}
                  />
                </Drawer>
              )}
            </div>
            {/* filter ends */}

            {/* <div> */}
            {/* <div>
              <Button
                ref={this.anchorRefLocation}
                aria-controls={openLocation ? "menu-list-grow1" : undefined}
                aria-haspopup="true"
                onClick={(e) => this.handleToggleLocation(e)}
                endIcon={openLocation ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                className={classes.filterDropdown}
              >
                {selectedLocationId !== null
                  ? locationData?.find((data) => data.id == selectedLocationId)
                      ?.location_name
                  : "Select Option"}
              </Button>
              <Popper
                open={openLocation}
                anchorEl={this.anchorRefLocation.current}
                role={undefined}
                transition
                disablePortal
                className={classes.filterDropdown}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.handleCloseLocation}>
                        <MenuList
                          autoFocusItem={openLocation}
                          id="menu-list-grow1"
                        >
                          {locationData.map((option, index) => (
                            <MenuItem
                              key={option?.id}
                              selected={option?.id == selectedLocationId}
                              onClick={(event) =>
                                this.handleMenuItemClickLocation(
                                  event,
                                  option?.id
                                )
                              }
                            >
                              {option?.location_name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <div>
              <Button
                ref={this.anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={(e) => this.handleToggle(e)}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                className={classes.filterDropdown}
              >
                {selectedIndex !== null
                  ? // options[
                    //     selectedIndex <= 0 ? selectedIndex : selectedIndex - 1
                    //   ]
                    options[selectedIndex]?.name
                  : "Select Option"}
              </Button>
              <Popper
                open={open}
                anchorEl={this.anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={classes.filterDropdown}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                          {options.map((option, index) => (
                            <MenuItem
                              key={option?.val}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                this.handleMenuItemClick(event, index)
                              }
                            >
                              {option?.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div> */}
            {/* </div> */}
          </AccordionSummary>
          {/* {this.getPrvilegesList()} */}
          {this.state.privilegeDataByLocation &&
            this.state.privilegeDataByLocation.map((data, index, arr) => (
              <Accordion
                style={{
                  borderBottom:
                    index !== arr.length - 1
                      ? "1px solid rgb(207, 207, 207)"
                      : "none",
                  marginBottom: index === arr.length - 1 && "50px",
                }}
                expanded={this.state.innerExpanded === `innerPanel${index}`}
                onChange={this.handleInnerChange(
                  `innerPanel${index}`,
                  data?.locations,
                  `location${data.id}`
                )}
                key={`innerPanel${index}`}
                className={`${
                  data?.benefit_quantity_used == data?.benefit_quantity &&
                  data?.is_evoucher != "1"
                    ? classes.disabled
                    : ``
                } ${this.state.appliedStatusId == 0 ? classes.disabled : ``}`}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  className="leftArrow"
                >
                  <div className="privilege-item">
                    {/* <div className={`${classes.leftIcon} ${classes.img}` }> */}
                    <div className={`${classes.leftIcon}`}>
                      {/* <LocalParkingIcon /> */}
                      <img src={data?.image_name} className={classes.img} />
                    </div>
                    <div className={classes.textBox}>
                      <div className={classes.detail}>
                        <b className={classes.titleBox}>
                          {truncateText(data?.privilege_name, 18, "")}{" "}
                          <Chip
                            size="small"
                            label={`${data?.validity_period}`}
                            className={classes.validityPeriod}
                          />
                        </b>
                        <br />

                        <div
                          className={`${classes.secondaryHeading} ${
                            classes.noWrap
                          } ${
                            data?.benefit_quantity_used ==
                              data?.benefit_quantity &&
                            data?.is_evoucher != "1" &&
                            `colorUsed`
                          }`}
                        >
                          {data?.benefit_quantity_used}/{data?.benefit_quantity}{" "}
                          Used
                        </div>
                      </div>
                      <div className={classes.bottomBox}>
                        <p
                          className={classes.secondaryHeading}
                          dangerouslySetInnerHTML={{
                            __html: truncateHTMLWithStructure(
                              data?.short_description,
                              this.state.innerExpanded === `innerPanel${index}`
                                ? 0
                                : 40
                            ),
                          }}
                        ></p>
                        <div
                          style={{ whiteSpace: "nowrap", marginRight: "-30px" }}
                          className={`${classes.secondaryHeading}  ${
                            data?.benefit_quantity_used ==
                              data?.benefit_quantity &&
                            data?.is_evoucher != "1" &&
                            `colorUsed`
                          }`}
                        >
                          {data?.locations[0]?.location_name}
                          {data?.locations?.length > 1
                            ? `+ ${data?.locations?.length - 1}`
                            : null}
                        </div>
                        {/* <div
                          className={`${classes.secondaryHeading} ${classes.noWrap}`}
                        >
                          {data?.benefit_quantity_used}/{data?.benefit_quantity}{" "}
                          Available
                        </div> */}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <div className={classes.panelDetail}>
                  {/* <div>
                    <Button
                      key={data?.id}
                      ref={this.anchorRefDetailLocation[data?.id]}
                      aria-controls={
                        openDetailLocation ? `detailmenu-list-grow${data?.id}` : undefined
                      }
                      aria-haspopup="true"
                      onClick={(e) => this.handleToggleDetailLocation(e)}
                      endIcon={
                        openDetailLocation ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      }
                      className={classes.filterDropdown}
                    >
                      {selectedDetailLocationId !== null
                        ? locationData?.find(
                            (data) => data.id == selectedDetailLocationId
                          )?.location_name
                        : "Select Option"}
                    </Button>
                    <Popper
                      open={openDetailLocation}
                      anchorEl={this.anchorRefDetailLocation[data?.id]?.current}
                      role={undefined}
                      transition
                      disablePortal
                      className={classes.filterDropdown}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={() => this.handleCloseDetailLocation(data?.id)}
                            >
                              <MenuList
                                autoFocusItem={openDetailLocation}
                                id={`detailmenu-list-grow${data?.id}`}
                              >
                                {detailLocationData.map((option, index) => (
                                  <MenuItem
                                    key={option?.id}
                                    selected={
                                      option?.id == selectedDetailLocationId
                                    }
                                    onClick={(event) =>
                                      this.handleMenuItemClickLocation(
                                        event,
                                        option?.id
                                      )
                                    }
                                  >
                                    {option?.location_name}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div> */}
                  {/* {console.log(data?.locations)} */}
                  {/* {console.log(
                    this.state.selectedDetailLocations.find(
                      (data) => data.id == selectedDetailLocationId
                    )?.location_name
                  )} */}
                  {/* {console.log(this.state.selectedDetailLocations)}

                  {console.log(selectedDetailLocationId)}
                  {console.log(this.state.selectedDetailLocations)} */}
                  {/* {data?.locations?.map(
                    (loc) =>
                      loc.id == selectedDetailLocationId && (
                        <>{loc.location_name}</>
                      )
                  )} */}
                  {/* {console.log(data?.locations?.filter(
                          (loc) =>
                            loc.id == selectedDetailLocationId
                        ).location_name)} */}
                  {/* {console.log(this.state.selectedDetailLocations)} */}
                  {/* {console.log(this.state.selectedDetailLocations.filter((data) =>
                            data.id == selectedDetailLocationId
                          ).location_name)} */}
                  {data?.locations?.length > 1 ? (
                    <FormControl>
                      <Select
                        name={`location${data.id}`}
                        id={`location${data.id}`}
                        value={
                          `location${data.id}` == this.state.selectLocId &&
                          data?.locations?.find(
                            (data) => data.id == selectedDetailLocationId
                          )?.location_name
                        }
                        disabled={
                          data?.benefit_quantity_used == data?.benefit_quantity
                        }
                        onChange={(e) =>
                          this.handleMenuItemClickDetailLocation(e)
                        }
                        input={<CustomInput />}
                        IconComponent={() => (
                          <img
                            className={classes.down_icon}
                            src={down_arrow}
                            alt=""
                            width="10"
                            height="6"
                          />
                        )}
                        MenuProps={MenuProps}
                      >
                        {data?.locations.map((location) => (
                          <MenuItem
                            selected={location?.id == selectedDetailLocationId}
                            key={location.id}
                            value={location.location_name}
                            onClick={(e) =>
                              this.handleMenuItemClickDetailLocationValue(
                                e,
                                location.id
                              )
                            }
                          >
                            {location.location_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : data?.is_evoucher == "0" ? (
                    <FormControl>
                      <Select
                        name={`location${data.id}`}
                        id={`location${data.id}`}
                        value={
                          `location${data.id}` == this.state.selectLocId &&
                          data?.locations.find(
                            (location) =>
                              location.id == selectedDetailLocationId
                          ).location_name
                        }
                        disabled={
                          data?.benefit_quantity_used == data?.benefit_quantity
                        }
                        onChange={(e) =>
                          this.handleMenuItemClickDetailLocation(e)
                        }
                        input={<CustomInput />}
                        IconComponent={() => (
                          <img
                            className={classes.down_icon}
                            src={down_arrow}
                            alt=""
                            width="10"
                            height="6"
                          />
                        )}
                        MenuProps={MenuProps}
                      >
                        {data.locations?.map((location) => (
                          <MenuItem
                            selected={location?.id == selectedDetailLocationId}
                            key={location.id}
                            value={location.location_name}
                            onClick={(e) =>
                              this.handleMenuItemClickDetailLocationValue(
                                e,
                                location.id
                              )
                            }
                          >
                            {location.location_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : null}

                  <div style={{ marginBottom:data?.is_evoucher == "0" ? "20px":"0px" }}></div>

                  <div>
                    {data?.is_evoucher == "1" && data?.voucher_data?.length && (
                      <Carousel
                        indicators={
                          data?.voucher_data?.length > 1 ? true : false
                        }
                        cycleNavigation={false}
                        autoPlay={false}
                        NextIcon={<ChevronRightIcon />}
                        PrevIcon={<ChevronLeftIcon />}
                        navButtonsAlwaysVisible={true}
                        className="qr-code-wrapper"
                        indicatorIconButtonProps={{
                          style: {
                            color: "#bbb",
                          },
                        }}
                        activeIndicatorIconButtonProps={{
                          style: {
                            color: "#3077AC",
                          },
                        }}
                        indicatorContainerProps={{
                          style: {
                            marginTop: "20px", // Adjust as per your requirement
                            display: "flex",
                            justifyContent: "center",
                            gap: "3px",
                          },
                        }}
                        navButtonsWrapperProps={{
                          style: {
                            height: "65%", // Set your desired height for the button wrapper
                          },
                        }}
                        navButtonsProps={{
                          className: classes.navButton,
                        }}
                      >
                        {data?.voucher_data?.map((qrInfo, index) => {
                          return (
                            <>
                              <Paper
                                style={{
                                  display: "inline-block",
                                  padding: "10px",
                                  backgroundColor: "#FFFFFF",
                                }}
                              >
                                <div
                                  key={index}
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                  }}
                                >
                                  <QRCode
                                    size={154}
                                    // sx={{ width: "100%" }}
                                    value={`${qrInfo.voucher_code}#${qrInfo.voucher_pin}#${userInfo.mobile_number}`}
                                  />
                                </div>
                              </Paper>
                              <div style={{ marginBottom: "20px" }}></div>

                              {qrInfo?.voucher_code ? (
                                <p className={classes.numBox}>
                                  Voucher code: {qrInfo.voucher_code}
                                </p>
                              ) : null}
                              {qrInfo?.voucher_pin ? (
                                <p className={classes.numBox}>
                                  Voucher pin: {qrInfo.voucher_pin}
                                </p>
                              ) : null}
                            </>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>

                  {data?.is_evoucher == "0" && (
                    <Paper
                      style={{
                        display: "inline-block",
                        padding: "10px",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <QRCode
                        size={154}
                        // value={
                        //   data?.benefit_quantity_used == data?.benefit_quantity ||
                        //   options[this.state.selectedIndex].val == 0
                        //     ? ""
                        //     : "https://google.com"
                        // }
                        // value={
                        //  "https://google.com"
                        // }

                        value={`${
                          data?.pwa_url
                        }redeem-points/add-to-cart?customerId=${
                          userInfo.id
                        }&merchantId=${apiData.merchant_id}&mobileNo=${
                          userInfo.mobile_number
                        }&productId=${data?.products_merchant_id}&locId=${
                          selectedDetailLocationId || data?.locations[0].id
                        }&isParking=${data?.is_parking}`}
                      />
                    </Paper>
                  )}

                  <div style={{ marginBottom: "20px" }}></div>
                  {data?.is_evoucher === "1" && data?.showevoucherbtn === 1 && (
                    <>
                      <p className={classes.secondaryHeading}>
                        {parseInt(data?.benefit_quantity_used ?? "0") === 0
                          ? "Click 'Get Voucher' to instantly access your unique code and enjoy exclusive offers!"
                          : parseInt(data?.benefit_quantity ?? "0") -
                              parseInt(data?.benefit_quantity_used ?? "0") >
                            0
                          ? "Click 'Get Remaining Voucher' to instantly access your remaining code and enjoy exclusive offers!"
                          : null}
                      </p>
                      {(parseInt(data?.benefit_quantity_used ?? "0") === 0 ||
                        parseInt(data?.benefit_quantity ?? "0") -
                          parseInt(data?.benefit_quantity_used ?? "0") >
                          0) && (
                        <div className={classes.btnContainer}>
                          <Button
                            disableRipple
                            color="primary"
                            variant="contained"
                            onClick={(e) =>
                              this.handleGetEvoucherCode(e, [
                                {
                                  product_merchant_id:
                                    data?.products_merchant_id,
                                  qty:
                                    parseInt(data?.benefit_quantity ?? "0") -
                                    parseInt(
                                      data?.benefit_quantity_used ?? "0"
                                    ),
                                  location_id:
                                    selectedDetailLocationId ||
                                    data?.locations?.[0]?.id,
                                  best_price: 0,
                                  calculated_points: 0,
                                },
                              ])
                            }
                          >
                            {parseInt(data?.benefit_quantity_used ?? "0") === 0
                              ? "Get Voucher"
                              : "Get Remaining Voucher"}
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  {/* <p className={classes.numBox}>123456779</p>
                  <p className={classes.secondaryHeading}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p> */}
                </div>
              </Accordion>
            ))}

          {/* <Accordion
            expanded={this.state.innerExpanded === "innerPanel3"}
            onChange={this.handleInnerChange("innerPanel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <div className="privilege-item">
                <div className={classes.leftIcon}>
                  <ConfirmationNumberIcon />
                </div>
                <div className={classes.detail}>
                  <p>
                    <b>Movie Tickets</b>
                    <p className={classes.secondaryHeading}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </p>
                  </p>
                </div>
                <div className={classes.secondaryHeading}>2/3 Available</div>
              </div>
            </AccordionSummary>
            <div className={classes.panelDetail}>
              <QRCode size={154} value={"https://google.com"} />
            </div>
          </Accordion> */}
          {/* <Accordion
            expanded={this.state.innerExpanded === "innerPanel4"}
            onChange={this.handleInnerChange("innerPanel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <div className="privilege-item">
                <div className={classes.leftIcon}>
                  <AssistantIcon />
                </div>
                <div className={classes.detail}>
                  <p>
                    <b>Personal Assistant</b>
                    <p className={classes.secondaryHeading}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </p>
                  </p>
                </div>
                <div className={classes.secondaryHeading}>2/3 Available</div>
              </div>
            </AccordionSummary>
            <div className={classes.panelDetail}>
              <QRCode size={154} value={"https://google.com"} />
            </div>
          </Accordion> */}
        </Accordion>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Privilege));
