import React, { Component, Fragment } from "react";
import classnames from "classnames";
import { Grid, IconButton, Divider } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { stockAPI } from "../../../../common/axiosInstance";
import cardBg320 from "../../../../assets/icons/myMembership/cardBg320.png";
import cardBg360 from "../../../../assets/icons/myMembership/cardBg360.png";
import cardBg480 from "../../../../assets/icons/myMembership/cardBg480.png";
import cardBg640 from "../../../../assets/icons/myMembership/cardBg640.png";
import cardBg from "../../../../assets/icons/myMembership/cardBg.png";
import circle_tick from "../../../../assets/icons/submit_bill/orange_tick.svg";
import { apiData } from "../../../../common/common-types";
import { getStorageItem } from "../../../../common/localStorage";
import {
  primaryOrange,
  color,
  lightGrey,
} from "../../../../components/UI/Theme";
import toggle_closed_btn_icon from "../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import toggle_open_btn_icon from "../../../../assets/icons/submit_bill/toggle_open_btn_icon.svg";
import LoyaltyInfo from "../../Transactions/components/LoyaltyInfo";

const styles = (theme) => {
  return {
    pageContainer: {
      padding: "30px",
      backgroundColor: theme.palette.common.globalBackground,
      height: "100%",
      display: "block",
      textAlign: "center",
      [theme.breakpoints.down("641")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "20px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },

    pageItem: {
      width: "100%",
      display: "block",
      justifyContent: "center",
      marginBottom: "30px",
      [theme.breakpoints.down(641)]: {
        marginBottom: "17px",
      },
      [theme.breakpoints.down(481)]: {
        marginBottom: "13px",
      },
      [theme.breakpoints.down(381)]: {
        marginBottom: "10px",
      },
      [theme.breakpoints.down(321)]: {
        marginBottom: "8px",
      },
    },

    pageItemSelectButton: {
      display: "block",
      marginBottom: theme.spacing(2),
      justifyContent: "center",
    },
    pageItemOne: {
      width: "100%",
      display: "block",
    },
    root: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    tab: {
      textTransform: "none",
      textAlign: "center",
      padding: theme.spacing(1),
      opacity: 1,
      color: "#000000",
      width: "50%",
      maxWidth: "50%",
      display: "block",
      float: "left",
      height: "92px",
      fontSize: "22px",
      lineHeight: "1.8",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
        lineHeight: "1.78",
        height: "80px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
        lineHeight: "1.71",
        height: "54px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        lineHeight: "1.5",
        height: "46px",
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
        lineHeight: "1.33",
        height: "40px",
      },
    },
    selected: {
      background: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
    lastIndicator: {
      width: "137px !important",
    },
    firstIndicator: {
      width: "137px !important",
      marginLeft: "4px !important",
    },
    uploadBtn: {
      textTransform: "none",
      color: "#FFFFFF",
      fontSize: "0.75rem",
    },
    uploadImage: {
      maxWidth: "100%",
      maxHeight: "500px",
    },
    mainRectangle: {
      width: "100%",
      height: "137px",
    },
    firstRectangle: {
      height: "20%",
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      backgroundColor: "#ffffff",
    },
    secondRectangle: {
      height: "20%",
      backgroundColor: "#ececec",
    },
    brLTB: {
      "& .MuiTab-root": {
        borderBottomLeftRadius: "4px",
        borderTopLeftRadius: "4px",
      },
    },
    brRTB: {
      "& .MuiTab-root": {
        borderBottomRightRadius: "4px",
        borderTopRightRadius: "4px",
      },
    },
    tabContainer: {
      margin: "0 auto",
      width: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("641")]: {
        marginTop: "12px",
        width: "100%",
      },
      [theme.breakpoints.down("461")]: {
        marginTop: "12px",
        width: "100%",
      },
      [theme.breakpoints.down("381")]: {
        marginTop: "12px",
        width: "100%",
      },
      [theme.breakpoints.down("321")]: {
        marginTop: "12px",
        width: "100%",
      },
      "& button": {
        [theme.breakpoints.between(768, `${window.innerWidth + 1}`)]: {
          padding: "1.3rem",
        },
      },
    },
    textRight: {
      [theme.breakpoints.between(768, `${window.innerWidth + 1}`)]: {
        textAlign: "right",
      },
    },
    pointsWrapper1: {
      fontFamily: "Montserrat",
      fontSize: "1.2rem",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      float: "left",
      margin: "15px 0px 15px 0px",
      width: "50%",
      borderRight: "1px solid #e7e8e5",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
        margin: "11px 0px 20px 0px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
        margin: "5px 0px 9px 0px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
        margin: "10px 0px 15px 0px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
        margin: "6px 0px 8px 0px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
        margin: "6px 0px 7px 0px",
      },
    },
    pointsWrapper: {
      padding: "0.2rem",
      [theme.breakpoints.between(0, 769)]: {
        padding: "0.2rem",
      },
      [theme.breakpoints.down("sm")]: {
        height: "120px",
      },
      [theme.breakpoints.down("641")]: {
        height: "107px",
      },
      [theme.breakpoints.down("481")]: {
        height: "81px",
      },
      [theme.breakpoints.down("381")]: {
        height: "60px",
      },
      [theme.breakpoints.down("321")]: {
        height: "55px",
      },
    },
    pointsWrapper2: {
      fontFamily: "Montserrat",
      fontSize: "1.2rem",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      float: "left",
      margin: "15px 0px 15px 0px",
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
        margin: "11px 0px 20px 0px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
        margin: "5px 0px 9px 0px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
        margin: "10px 0px 15px 0px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
        margin: "6px 0px 8px 0px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
        margin: "6px 0px 8px 0px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
        lineHeight: "1.6",
        margin: "6px 0px 7px 0px",
      },
    },

    outerWrap: {
      width: "720px",
      backgroundColor: "#f5f5f5",
      margin: "0 auto",
    },
    mainWrapper: {
      margin: "0 auto",
      padding: "0",
      width: "100%",
      display: "block",
      position: "relative",
      // [theme.breakpoints.between(0, 769)]: {
      //   width: "98%",
      // },
    },
    bgTras: {
      backgroundColor: "transparent !important",
    },

    loyaltyCard: {
      width: "100%",
      maxWidth: "100%",
      borderRadius: "30px",
      [theme.breakpoints.down(641)]: { borderRadius: "18px" },
      [theme.breakpoints.down(481)]: { borderRadius: "13px" },
      [theme.breakpoints.down(381)]: { borderRadius: "10px" },
      [theme.breakpoints.down(321)]: { borderRadius: "8px" },
    },
    top: {},
    cardNumber: {},
    name: {},
    number: {},
    cardName: {},
    box: {},
    box1: {},
    last: {},
    cardCnt: {
      padding: "0px",
      display: "block",
      borderRadius: "30px",
      [theme.breakpoints.down(641)]: { borderRadius: "18px" },
      [theme.breakpoints.down(481)]: { borderRadius: "13px" },
      [theme.breakpoints.down(381)]: { borderRadius: "10px" },
      [theme.breakpoints.down(321)]: { borderRadius: "8px" },
      boxShadow: "0px 0px 16px #c3c3c3",
      overflow: "hidden",
      "& $top": {
        margin: "0",
        padding: "31px 20px 23px 30px",
        display: "block",
        backgroundColor: "transparent",
        backgroundImage: `url(${cardBg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left top",
        color: "#fff",
        backgroundSize: "cover",
        position: "relative",
        textAlign: "left",
        [theme.breakpoints.down(641)]: {
          height: "100%",
          padding: "26px 18px 24px 26px",
          backgroundImage: `url(${cardBg640})`,
        },
        [theme.breakpoints.down(480)]: {
          height: "79px",
          padding: "16px 13px 16px 19px",
          backgroundImage: `url(${cardBg480})`,
        },
        [theme.breakpoints.down(381)]: {
          height: "59px",
          padding: "8px 10px 11px 14px",
          backgroundImage: `url(${cardBg360})`,
        },
        [theme.breakpoints.down(321)]: {
          height: "52px",
          padding: "6px 8px 10px 13px",
          backgroundImage: `url(${cardBg320})`,
        },
        "& $cardNumber": {
          width: "100%",
          display: "block",
          marginBottom: "19px",
          [theme.breakpoints.down("641")]: {
            marginBottom: "16px",
          },
          [theme.breakpoints.down("481")]: {
            marginBottom: "11px",
          },
          [theme.breakpoints.down("381")]: {
            marginBottom: "4px",
          },
          [theme.breakpoints.down("321")]: {
            marginBottom: "4px",
          },
          padding: "0px",
          "& $name": {
            display: "inline-block",
            fontWeight: "500",
            paddingRight: "5px",
            fontSize: "16px",
            [theme.breakpoints.down("481")]: {
              fontSize: "12px",
            },
            [theme.breakpoints.down("321")]: {
              fontSize: "10px",
            },
          },
          "& $number": {
            display: "inline-block",
            fontWeight: "500",
            fontSize: "16px",
            [theme.breakpoints.down("481")]: {
              fontSize: "12px",
            },
            [theme.breakpoints.down("321")]: {
              fontSize: "10px",
            },
          },
        },
        "& $cardName": {
          width: "100%",
          height: "30px",
          fontSize: "27.5px",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1,
          letterSpacing: "normal",
          display: "block",
          padding: "0px",
          fontWeight: "600",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          [theme.breakpoints.down("641")]: {
            fontSize: "27.5px",
            height: "30px",
          },
          [theme.breakpoints.down("481")]: {
            fontSize: "22px",
            height: "24px",
          },
          [theme.breakpoints.down("421")]: {
            fontSize: "17.5px",
            height: "18.5px",
          },
          [theme.breakpoints.down("401")]: {
            fontSize: "17px",
            height: "18px",
          },
          [theme.breakpoints.down("381")]: {
            fontSize: "16.5px",
            height: "17.5px",
          },
          [theme.breakpoints.down("361")]: {
            fontSize: "16px",
            height: "18px",
          },
          [theme.breakpoints.down("321")]: {
            fontSize: "14px",
            height: "16px",
            margin: "5px 0px 0px 0px",
          },
        },
      },
    },
    gold: {
      margin: "0px 5px 0px 0px",
      padding: "8px 18px",
      textTransform: "uppercase",
      display: "inline-block",
      borderRadius: "20px",
      backgroundColor: theme.palette.tier.gold,
      position: "absolute",
      bottom: "27px",
      right: "10px",
      textAlign: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "clip",
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
        height: "37px",
        bottom: "24px",
        right: "18px",
        padding: "14px 7px",
        lineHeight: "0.65rem",
        margin: "0px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
        height: "28px",
        bottom: "15px",
        right: "13px",
        padding: "7px 7px",
        lineHeight: "0.85rem",
        margin: "0px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        bottom: "14px",
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        bottom: "10px",
        height: "21px",
        right: "10px",
        padding: "5px 7px",
        lineHeight: "0.65rem",
        margin: "0px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
        height: "19px",
        bottom: "10px",
        right: "10px",
        padding: "5px 7px",
        lineHeight: "0.65rem",
        margin: "0px",
      },
    },
    code: {
      margin: "0px",
      padding: "5px 0",
      display: "block",
      width: "100%",
      textAlign: "center",
      position: "relative",
      background: "#fff",
      "& img": {
        maxWidth: "100%",
        width: "80%",
        objectFit: "cover",
        objectPosition: "center",
        [theme.breakpoints.between(0, 415)]: {
          height: "100px",
        },
        [theme.breakpoints.between(768, `${window.innerWidth + 1}`)]: {
          height: "220px",
        },
      },
    },
    cardGrid: {
      width: "100%",
      maxWidth: "100%",
    },
    avalPoints: {
      fontWeight: "bold",
      fontSize: "24px",
      [theme.breakpoints.down("641")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    currencySym: {
      fontWeight: "bold",
      fontSize: "30px",
      [theme.breakpoints.down("481")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "16.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "14px",
      },
    },
    clearBoth: {
      clear: "both",
    },
    tierCard: {
      paddingBottom: "20px",
      [theme.breakpoints.down("641")]: {
        paddingBottom: "17px",
      },
      [theme.breakpoints.down("481")]: {
        paddingBottom: "13px",
      },
      [theme.breakpoints.down("381")]: {
        paddingBottom: "10px",
      },
      [theme.breakpoints.down("321")]: {
        paddingBottom: "8px",
      },
    },
    last: {
      borderRight: "none !important",
    },
    bottom: {},
    top: {},
    left: {},
    right: {},
    content: {},
    loatChild: {},
    floatChild: {},
    billtotalPoints: {
      padding: "0px",
      display: "block",
      boxShadow: "0px 0px 10px #dad4d4",
      background: "#fff",
      // borderRadius: "6px",
      "& $box": {
        margin: "0px",
        padding: "29px 19px",
        display: "inline-block",
        width: "33%",
        fontFamily: "Montserrat",
        borderRight: "1px solid #d8d8d8",
        fontSize: "18px",
        fontWeight: "500",
        textAlign: "left",
        color: theme.palette.typography.texts.subHeading,
        verticalAlign: "middle",
        height: "100%",
        [theme.breakpoints.down(641)]: {
          fontSize: "18px",
          padding: "15px 6px",
          textAlign: "center",
        },
        [theme.breakpoints.down(481)]: {
          padding: "10px 4px",
          fontSize: "12px",
          textAlign: "center",
        },
        [theme.breakpoints.down(421)]: {
          padding: "5px 5px",
          textAlign: "center",
          fontSize: "11.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "11px",
          padding: "10px 4px",
          textAlign: "center",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "10.5px",
          padding: "8px 5px",
          textAlign: "center",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "10px",
          padding: "8px 3px",
          textAlign: "center",
        },
        [theme.breakpoints.down(321)]: {
          padding: "5px 1px",
          textAlign: "center",
        },
      },
      "& $box1": {
        margin: "0px",
        padding: "29px 68px",
        display: "inline-block",
        width: "33%",
        fontFamily: "Montserrat",
        borderRight: "1px solid #d8d8d8",
        fontSize: "18px",
        fontWeight: "500",
        textAlign: "left",
        color: theme.palette.typography.texts.subHeading,
        verticalAlign: "middle",
        height: "100%",
        [theme.breakpoints.down(641)]: {
          fontSize: "18px",
          padding: "15px 33px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(481)]: {
          padding: "10px 19px",
          fontSize: "12px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(421)]: {
          padding: "5px 19px",
          // textAlign: "center",
          fontSize: "11.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "11px",
          padding: "10px 19px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "10.5px",
          padding: "8px 13px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "10px",
          padding: "8px 13px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(321)]: {
          padding: "5px 13px",
          // textAlign: "center",
        },
      },
      "& $bottom": {
        margin: "0px",
        padding: "0px",
        fontFamily: "Montserrat",
        display: "inline-block",
        width: "100%",
        height: "100%",
        // height: "54px",
        background: "#ececec",
        // [theme.breakpoints.down(641)]: {
        //   height: "49px",
        // },
        // [theme.breakpoints.down(481)]: {
        //   height: "37px",
        // },
        // [theme.breakpoints.down(381)]: {
        //   height: "28px",
        // },
        // [theme.breakpoints.down(321)]: {
        //   height: "25px",
        // },
      },
      "& $top": {
        margin: "0px",
        padding: "32px 36px 32px 72px",
        display: "flex",
        fontFamily: "Montserrat",
        // borderTopLeftRadius: "4px",
        // borderTopRightRadius: "4px",
        justifyContent: "space-between",
        alignItems: "center",
        height: "92px",
        width: "100%",
        background: "#ffffff",
        [theme.breakpoints.down("641")]: {
          height: "80px",
          padding: "32px 10px 32px 32px",
        },
        [theme.breakpoints.down("481")]: {
          height: "54px",
          padding: "16px 19px",
        },
        [theme.breakpoints.down("381")]: {
          // padding: "16px",
          height: "46px",
          padding: "16px 14px",
        },
        [theme.breakpoints.down("321")]: {
          height: "40px",
          padding: "16px 13px",
        },
        "& $left": {
          textAlign: "left",
          fontSize: "1rem",

          [theme.breakpoints.between(0, 321)]: {
            padding: "7px 5px",
          },
          [theme.breakpoints.between(0, 415)]: {
            padding: "7px 0px 5px 15px",
          },
        },
        "& $right": {
          fontSize: "1rem",
          [theme.breakpoints.between(0, 321)]: {
            textAlign: "right",
          },
          [theme.breakpoints.between(0, 415)]: {
            textAlign: "right",
          },
        },
        "& $content": {
          fontWeight: "500",
          lineHeight: "1.67",
          fontStretch: "normal",
          letterSpacing: "normal",
          fontSize: "30px",
          [theme.breakpoints.down("641")]: {
            fontSize: "27.5px",
          },
          [theme.breakpoints.down("481")]: {
            fontSize: "22px",
          },
          [theme.breakpoints.down("421")]: {
            fontSize: "17.5px",
          },
          [theme.breakpoints.down("401")]: {
            fontSize: "17px",
          },
          [theme.breakpoints.down("381")]: {
            fontSize: "16.5px",
          },
          [theme.breakpoints.down("361")]: {
            fontSize: "16px",
          },
          [theme.breakpoints.down("321")]: {
            fontSize: "14px",
          },
        },
        "& $floatChild": {
          width: "50%",
          float: "left",
        },
      },
    },
    hereText: {
      fontWeight: "500",
      textAlign: "right",
      color: "#0076ac",
      paddingTop: "0px",
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    inlineFlex: {
      display: "inline-flex",
      alignItems: "center",
    },
    flotLeft: {
      float: "left",
    },
    mr10: {
      marginRight: "10px",
    },
    avalPointsText: {
      fontSize: "24px",
      [theme.breakpoints.down("641")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    currencyText: {
      fontSize: "18px",
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    tick: {
      width: "30px",
      height: "30px",
      "--size ": "70px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      [theme.breakpoints.down("641")]: {
        width: "27px",
        height: "27px",
      },
      [theme.breakpoints.down("481")]: {
        width: "21px",
        height: "21px",
      },
      [theme.breakpoints.down("381")]: {
        width: "16px",
        height: "16px",
      },
      [theme.breakpoints.down("321")]: {
        width: "14px",
        height: "14px",
      },
    },
    benifit: {
      fontSize: "18px",
      textAlign: "justify",
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    infinitySymbol: {
      color: "#7e7e7e",
      display: "contents",
      fontSize: "22px",
      paddingLeft: "3px",
      verticalAlign: "middle",
      [theme.breakpoints.down("610")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
    },
    mainTabs: {
      minHeight: "40px",
      "& .MuiTab-root": {
        minHeight: "40px",
      },
    },
    availWrapper: {
      width: "100%",
      height: "77px",
      objectFit: "contain",
      borderRadius: "5px",
      backgroundColor: theme.palette.cards.backgroundColor,
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "2",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "30px",
      marginBottom: "30px",
      [theme.breakpoints.down("641")]: {
        height: "60px",
        padding: "24px",
        marginBottom: "26px",
      },
      [theme.breakpoints.down("481")]: {
        height: "44px",
        padding: "19px",
        marginBottom: "20px",
      },
      [theme.breakpoints.down("381")]: {
        height: "36px",
        padding: "7px 14px",
        marginBottom: "15px",
      },
      [theme.breakpoints.down("321")]: {
        height: "32px",
        padding: "7px 13px",
        marginBottom: "13px",
      },
    },
    common_font: {
      fontSize: "18px",
      textAlign: "justify",
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
    },
    circletickCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${circle_tick})`,
      "mask-image": `url(.${circle_tick})`,
      mask: `url(.${circle_tick}) no-repeat center / contain`,
      "-webkit-mask": `url(.${circle_tick}) no-repeat center / contain`,
    },
    downArrowCss: {
      width: "10px",
      height: "6px",
    },
    catergories_btn: {
      "& .MuiIconButton-root": {
        padding: "0 !important",
      },
    },
    pd10: {
      padding: "0px 16px 16px",
    },
    pd0: {
      padding: 0,
    },
    pdB0: {
      paddingBottom: "0px !important",
    },
    pdT0: {
      paddingTop: "0px !important",
    },
    categories_content: {
      "& .MuiDivider-root": {
        height: "2px",
        width: "100%",
        marginTop: "23",
        [theme.breakpoints.down(641)]: {
          marginTop: "13px",
        },
      },

      "& $content": {
        margin: "0px",
        padding: "30px",
        width: "auto",
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontWeight: "400",
        textAlign: "left",
        backgroundColor: "#ececec",
        color: theme.palette.typography.texts.color,
        verticalAlign: "middle",
        height: "auto",
        [theme.breakpoints.down(641)]: {
          fontSize: "18px",
          padding: "15px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(481)]: {
          // padding: "10px 19px",
          fontSize: "12px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(421)]: {
          // padding: "5px 19px",
          // textAlign: "center",
          fontSize: "11.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "11px",
          // padding: "10px 19px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "10.5px",
          // padding: "8px 13px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "10px",
          // padding: "8px 13px",
          // textAlign: "center",
        },
        [theme.breakpoints.down(321)]: {
          // padding: "5px 13px",
          // textAlign: "center",
        },
      },
    },
    Blue: {
      backgroundColor: 'var(--blue)',
    },
    Gold: {
      backgroundColor: 'var(--gold)',
    },
    Silver: {
      backgroundColor: 'var(--silver)',
    },
    Platinum: {
      color: '#a3a3a3',
      backgroundColor: 'var(--platinum)',
    },
  };
};

class MyMembership extends Component {
  state = {
    tabValue: 0,
    benefits: "No data available.",
    tiers: [
      {
        tier_id: "149",
        every_x_currency_spent: "0",
        earn_points: "0",
        x_points_equivalent_to: "0",
        x_currency: "0",
        tier_name: "Silver",
        range_starts_at: "0",
        range_ends_at: "0",
      },
    ],
    balancePoints: 0,
    currentTierId: null,
    loyaltyProgramName: "",
    currentTierName: "",
    membership_id: "",
    name: "",
    membership_bar_code: "",
    current_points_balance_in_rs: 0,
    customer_id: 0,
    platform: 4,
    loyalty_id: 0,
    merchant_id: 0,
    pointShortName: "P",
    currencyCode: "₹",
    isCategorieOpen: false,
    categorywise_tiers: [],
  };

  componentDidMount = async () => {
    await this.setDefaultParam();
    this.getBenefitTabData();
    this.getLoyaltyProgramTierDetails();
  };

  setDefaultParam = async () => {
    if (getStorageItem("user")) {
      this.setState({
        customer_id: JSON.parse(getStorageItem("user")).id,
      });
    }

    if (getStorageItem("pointShortName")) {
      this.setState({
        pointShortName: getStorageItem("pointShortName"),
      });
    }
    if (getStorageItem("currencyCode")) {
      this.setState({
        currencyCode: getStorageItem("currencyCode"),
      });
    }
    if (getStorageItem("loyaltyId")) {
      this.setState({
        loyalty_id: getStorageItem("loyaltyId"),
      });
    }
    this.setState({
      platform: apiData.platform,
      merchant_id: apiData.merchant_id,
    });
  };

  getLoyaltyProgramTierDetails = async () => {
    if (getStorageItem("user")) {
      this.props.openLoader(true);
      const params = new FormData();
      params.append("customer_id", this.state.customer_id);
      params.append("platform", this.state.platform);
      params.append("loyalty_id", this.state.loyalty_id);
      params.append("merchant_id", this.state.merchant_id);

      stockAPI(params, "POST", "/getLoyaltyProgramTierDetails")
        .then(async (res) => {
          if (res) {
            const status = res;
            this.setState({
              tiers: status.merchant_tiers,
              balancePoints:
                parseFloat(status.current_points_balance) % 1 !== 0
                  ? parseFloat(status.current_points_balance).toFixed(2)
                  : Math.floor(status.current_points_balance),
              currentTierId: status.customer_current_tier_id,
              loyaltyProgramName: status.loyalty_program_name_short,
              currentTierName: status.customer_current_tier_name,
              categorywise_tiers: status.categorywise_tiers,
              membership_id: status.membership_id,
              name: status.name,
              membership_bar_code: status.membership_bar_code,
            });

            this.state.tiers.map((tier, i) => {
              if (tier.tier_id === this.state.currentTierId) {
                this.setState({
                  current_points_balance_in_rs:
                    this.state.balancePoints *
                    (tier.x_points_equivalent_to * tier.x_currency),
                });
              }
              return true;
            });
            this.props.openLoader(false);
          } else if (res && res.message) {
            this.props.openLoader(false);
            openAlertBox(res && res.message, "error");
          } else {
            openAlertBox("Oops something went wrong!", "error");
          }
        })
        .catch((err) => {
          this.props.openLoader(false);
          openAlertBox(err && err.message, "error");
        });
    } else {
      this.props.logoutUser();
    }
  };

  getBenefitTabData = async () => {
    if (getStorageItem("user")) {
      this.props.openLoader(true);
      let url =
        "/getStaticWebPage?platform=" +
        this.state.platform +
        "&merchant_id=" +
        this.state.merchant_id +
        "&page_type=" +
        302;
      var benefitData = await stockAPI({}, "GET", url);

      if (benefitData) {
        this.setState({
          benefits: benefitData,
        });
      }

      this.props.openLoader(false);
    } else {
      this.props.logoutUser();
    }
  };
  thousandSeparator = (amt) =>
    amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  getRoundValue = (value, showRound) => {
    return showRound
      ? Math.round(value * 10) / 10
      : parseFloat(value).toFixed(2);
  };
  handleTabValueChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };
  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/);
    if (match) {
      return match[1] + " " + match[2] + " " + match[3];
    }
    return null;
  };

  toggleCategorie = () => {
    const { isCategorieOpen } = this.state;
    this.setState({
      isCategorieOpen: !isCategorieOpen,
    });
  };
  render() {
    const { classes } = this.props;
    const {
      benefits,
      tabValue,
      tiers,
      currentTierId,
      balancePoints,
      currentTierName,
      membership_id,
      name,
      membership_bar_code,
      current_points_balance_in_rs,
      pointShortName,
      currencyCode,
      isCategorieOpen,
      categorywise_tiers,
    } = this.state;

    const tabContainer = (
      <Paper
        className={classnames(classes.tabContainer, {
          [classes.brLTB]: tabValue === 0,
          [classes.brRTB]: tabValue === 1,
        })}
      >
        <Tabs
          classes={{
            indicator: classnames(classes.indicator, {
              [classes.firstIndicator]: tabValue === 0,
              [classes.lastIndicator]: tabValue === 1,
            }),
          }}
          value={tabValue}
          onChange={this.handleTabValueChange}
          centered
          className={classes.mainTabs}
        >
          <Tab
            label="Tier(s)"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
          />
          <Tab
            label="Benefits"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
          />
        </Tabs>
      </Paper>
    );
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.pageContainer}
      >
        <Grid className={classes.mainWrapper}>
          <Grid item className={classes.pageItem}>
            <Paper
              className={
                classes.root + " " + classes.bgTras + " " + classes.loyaltyCard
              }
            >
              <div className={classes.cardCnt}>
                <div className={classes.top}>
                  <div className={classes.cardNumber}>
                    <span className={classes.name}>Your Membership Card: </span>
                    <span className={classes.number}>
                      {" "}
                      {this.formatPhoneNumber(membership_id)}
                    </span>
                  </div>
                  <div className={classes.cardName}>{name}</div>
                  {tiers.length > 1 && (
                    <div className={`${classes.gold} ${classes[currentTierName]}`}>{currentTierName}</div>
                  )}
                </div>
                <div className={classes.code}>
                  <img src={membership_bar_code} alt={membership_id} />
                </div>
              </div>
            </Paper>
          </Grid>
          <div style={{marginBottom:'10px',marginTop:'10px'}}>
          <LoyaltyInfo  isVisible/>
          </div>
          <Grid item className={classes.pageItem}>
            <Paper className={classes.root + " " + classes.cardGrid}>
              {/* <div className={classes.pointsWrapper}>
                <div className={classes.pointsWrapper1}>
                  <div className={classes.avalPoints}>
                    {this.getRoundValue(balancePoints, 0)} {pointShortName}
                  </div>
                  <div className={classes.avalPointsText}>Available Points</div>
                </div>

                <div className={classes.pointsWrapper2}>
                  <div className={classes.currencySym}>
                    {currencyCode}{" "}
                    {this.thousandSeparator(
                      this.getRoundValue(current_points_balance_in_rs, 0)
                    )}
                  </div>
                  <div className={classes.currencyText}>Value in Rupees</div>
                </div>
                <div className={classes.clearBoth}></div>
              </div> */}
           
              <div className={classes.availWrapper}>
                <div className={classes.avalPointsText}>Available Points</div>
                <div className={classes.avalPoints}>
                  {balancePoints} {pointShortName}
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item className={classes.pageItem}>
            {tabContainer}
          </Grid>
          <Grid
            item
            className={classes.pageItem + " " + classes.main_page_item}
          >
            {tabValue === 0 ? (
              <Grid className={classes.root}>
                <Grid style={{ width: "100%" }}>
                  {tiers.map((tier, i) => {
                    return (
                      <Grid key={i} className={classes.tierCard}>
                        <div className={classes.billtotalPoints}>
                          <div className={classes.top}>
                            <Typography
                              className={classes.content}
                              style={{
                                color:
                                  tier.tier_id === currentTierId
                                    ? primaryOrange
                                    : color,
                              }}
                            >
                              {tier.tier_name}
                            </Typography>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {tier.tier_id === currentTierId ? (
                                <span
                                  className={
                                    classes.tick +
                                    " " +
                                    classes.flotLeft +
                                    " " +
                                    classes.mr10 +
                                    " " +
                                    classes.circletickCss
                                  }
                                />
                              ) : (
                                ""
                              )}

                              <Typography
                                className={classes.hereText}
                                style={{
                                  color:
                                    tier.tier_id === currentTierId
                                      ? primaryOrange
                                      : lightGrey,
                                }}
                              >
                                {tier.tier_id === currentTierId
                                  ? "You are here"
                                  : ""}
                              </Typography>
                            </div>
                          </div>
                          <div className={classes.bottom}>
                            <div className={classes.box1}>
                              {apiData.merchant_id !== 10071 && (
                                <strong style={{ color: "#7e7e7e" }}>
                                  {currencyCode}
                                </strong>
                              )}{" "}
                              {this.getRoundValue(tier.range_starts_at, 0)}{" "}
                              {apiData.merchant_id == 10071 && pointShortName} -{" "}
                              {apiData.merchant_id !== 10071 && (
                                <strong style={{ color: "#7e7e7e" }}>
                                  {currencyCode}
                                </strong>
                              )}{" "}
                              {Math.floor(tier.range_ends_at) === 9999999999 ? (
                                <span className={classes.infinitySymbol}>
                                  ∞
                                </span>
                              ) : (
                                this.getRoundValue(tier.range_ends_at, 0)
                              )}{" "}
                              {apiData.merchant_id == 10071 && pointShortName}
                            </div>
                            <div className={classes.box}>
                              Earn:{" "}
                              <strong style={{ color: "#7e7e7e" }}>
                                {currencyCode}
                              </strong>{" "}
                              {this.thousandSeparator(
                                this.getRoundValue(
                                  tier.every_x_currency_spent,
                                  1
                                )
                              )}{" "}
                              -{" "}
                              {this.thousandSeparator(
                                this.getRoundValue(tier.earn_points, 1)
                              )}{" "}
                              {pointShortName}{" "}
                            </div>
                            <div className={classes.box + " " + classes.last}>
                              Redeem:{" "}
                              {this.thousandSeparator(
                                this.getRoundValue(
                                  tier.x_points_equivalent_to,
                                  1
                                )
                              )}{" "}
                              {pointShortName} -{" "}
                              <strong style={{ color: "#7e7e7e" }}>
                                {currencyCode}
                              </strong>{" "}
                              {this.thousandSeparator(
                                this.getRoundValue(tier.x_currency, 1)
                              )}
                            </div>
                          </div>
                          {tier.tier_id === currentTierId &&
                            categorywise_tiers.length > 0 && (
                              <div
                                className={classnames(classes.catergories_btn, {
                                  [classes.pd10]: isCategorieOpen,
                                  [classes.pd0]: !isCategorieOpen,
                                })}
                              >
                                <IconButton
                                  className={classes.toggler_icon}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.toggleCategorie();
                                  }}
                                >
                                  <img
                                    src={
                                      isCategorieOpen
                                        ? toggle_open_btn_icon
                                        : toggle_closed_btn_icon
                                    }
                                    alt={isCategorieOpen ? "Open" : "Close"}
                                    className={classes.downArrowCss}
                                  />
                                </IconButton>
                                {isCategorieOpen && (
                                  <div className={classes.categories_content}>
                                    {categorywise_tiers.map(
                                      (tier_data, index) => {
                                        return (
                                          <div key={index}>
                                            <div
                                              className={classnames(
                                                classes.content,
                                                {
                                                  [classes.pdB0]:
                                                    index === 0 &&
                                                    categorywise_tiers.length -
                                                      1 !==
                                                      0,
                                                  // [classes.pdT0]:
                                                  //   index ===
                                                  //   categorywise_tiers.length - 1,
                                                }
                                              )}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <span
                                                  className={
                                                    classes.common_font
                                                  }
                                                >
                                                  {tier_data.cat_name}
                                                </span>
                                                <span>
                                                  Earn:{" "}
                                                  <strong
                                                    style={{ color: "#7e7e7e" }}
                                                  >
                                                    {currencyCode}
                                                  </strong>{" "}
                                                  {this.thousandSeparator(
                                                    this.getRoundValue(
                                                      tier_data.every_x_currency_spent,
                                                      1
                                                    )
                                                  )}{" "}
                                                  -{" "}
                                                  {this.thousandSeparator(
                                                    this.getRoundValue(
                                                      tier_data.earn_points,
                                                      1
                                                    )
                                                  )}{" "}
                                                  {pointShortName}{" "}
                                                </span>
                                              </div>
                                              {index !==
                                                categorywise_tiers.length -
                                                  1 && <Divider />}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ) : (
              <Grid className={classes.root}>
                <Grid style={{ width: "100%" }}>
                  <div
                    className={classes.content + " " + classes.benifit}
                    dangerouslySetInnerHTML={{
                      __html: benefits ? benefits : "No data available.",
                    }}
                  ></div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MyMembership);
