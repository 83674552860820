import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import crossIcon from "../../../../assets/icons/dashboard/cross-gray.svg";
import submitIcon from "../../../../assets/icons/dashboard/submitIcon.svg";
import redeemIcon from "../../../../assets/icons/dashboard/redeemIcon.svg";
import transactionIcon from "../../../../assets/icons/dashboard/transactionIcon.svg";
import visitMallIcon from "../../../../assets/icons/dashboard/visitMallIcon.svg";
import referFriendIcon from "../../../../assets/icons/dashboard/referFriendIcon.svg";
import whatsappIcon from "../../../../assets/icons/dashboard/whatsappIcon.svg";
import feedbackIcon from "../../../../assets/icons/dashboard/feedbackIcon.svg";
import RedeemProductList from "../components/RedeemProductList";
import { Grid, Modal } from "@material-ui/core";
import Carousal from "../../Redeem/components/Carousal";
import { stockAPI } from "../../../../common/axiosInstance";
import {
  getStorageItem,
  setStorageItem,
} from "../../../../common/localStorage";
import {
  apiData,
  WHATSAPP_LINK,
  WHATSAPP_SHARING_LINK,
} from "../../../../common/common-types";
import platform from "platform";

const styles = (theme) => {
  return {
    wrapper: {
      objectFit: "contain",
      backgroundColor: theme.palette.cards.backgroundColor,
    },
    innerWrap: {
      backgroundColor: theme.palette.primary.main,
      margin: "0px auto",
      overflow: "hidden",
      padding: "0 30px 30px",
      [theme.breakpoints.down("641")]: {
        padding: "0 28px 28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "0 21px 21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0 15px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0 13px 13px",
      },
    },
    loyaltyWrap: {
      margin: "0 auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      padding: "17px 0",
      [theme.breakpoints.down("641")]: {
        padding: "18px 0px 14px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "16px 0px 10px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "12px 0px 10px 0",
      },
    },
    loyaltyName: {
      fontSize: "34px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: theme.palette.dashBoardheadText.color,
      marginRight: "20px",
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 230,
      display: "contents",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down("641")]: {
        fontSize: "22px",
        marginRight: "13px",
        maxWidth: 150,
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "17.5px",
        marginRight: "13px",
        maxWidth: 110,
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17px",
        maxWidth: 110,
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "16.5px",
        maxWidth: 110,
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "16px",
        marginRight: "6px",
        maxWidth: 110,
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "15px",
        maxWidth: 100,
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "13.5px",
        marginRight: "5px",
        maxWidth: 90,
      },
    },
    loyaltyNameInner: {
      borderRadius: "3px",
      height: "53px",
      float: "right",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("641")]: {
        height: "49px",
      },
      [theme.breakpoints.down("481")]: {
        height: "37px",
      },
      [theme.breakpoints.down("381")]: {
        height: "28px",
      },
      [theme.breakpoints.down("321")]: {
        height: "25px",
      },
    },
    loyaltyNameInnerLabel: {
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#FFF",
      padding: "0 15px",
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
        padding: "0 10px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
        padding: "0 6px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    membershipLabel: {
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "right",
      color: theme.palette.dashBoardheadText.color,
      marginRight: "0px",
      textDecoration: "underline",
      cursor: "pointer",
      display: "inline-block",
      fontSize: "16px",
      width: "150px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
        width: "117px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
        width: "100px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
        width: "85px",
      },
      "&& a": {
        color: theme.palette.typography.texts.headerTextColor,
      },
    },
    pointsWrapper: {
      width: "100%",
      height: "120px",
      objectFit: "contain",
      borderRadius: "5px",
      backgroundColor: theme.palette.cards.backgroundColor,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("481")]: {
        height: "81px",
      },
      [theme.breakpoints.down("381")]: {
        height: "64px",
      },
      [theme.breakpoints.down("321")]: {
        height: "57px",
      },
    },
    pointsWrapper1: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "2",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      float: "left",
      width: "50%",
      borderRight: "1px solid #e7e8e5",
    },
    avalPoints: {
      fontWeight: "bold",
      fontSize: "24px",
      [theme.breakpoints.down("641")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    avalPoints1: {
      fontSize: "24px",
      [theme.breakpoints.down("641")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    pointsWrapper2: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "2",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      float: "left",
      width: "50%",
    },
    outerWrap: {
      width: "100%",
      backgroundColor: theme.palette.common.globalBackground,
      margin: "0 auto",
      padding: "0 30px 30px 30px",
      [theme.breakpoints.down("641")]: {
        padding: "0 28px 28px 28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "0 21px 21px 21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0 15px 15px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0 13px 13px 13px",
      },
    },
    multiBox: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "hidden",
      width: "100%",
      margin: "0 auto",
      paddingBottom: "5px",
      gap: "10px",
    },
    shortcutsLinkBox: {
      width: "calc(33.33% - 15px)",
      height: "122px",
      objectFit: "contain",
      backgroundColor: theme.palette.cards.backgroundColor,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: "3px",
      boxShadow: "0px 0px 4px #c3c3c3",
      textAlign: "center",
      [theme.breakpoints.down("641")]: {
        width: "calc(33.33% - 6px)",
        height: "102px",
      },
      [theme.breakpoints.down("481")]: {
        width: "calc(33.33% - 6px)",
        height: "82px",
      },
      [theme.breakpoints.down("421")]: {
        height: "80px",
      },
      [theme.breakpoints.down("381")]: {
        height: "76px",
      },
      [theme.breakpoints.down("321")]: {
        height: "62px",
      },
      "& .MuiLink-underlineHover:hover": {
        outline: "transparent",
        textDecoration: "none",
        border: "none",
      },
      "& .MuiLink-underlineHover:focus": {
        outline: "transparent",
        textDecoration: "none",
        border: "none",
      },
      "&:focus": {
        outline: "transparent",
        border: "none",
        textDecoration: "none",
      },
    },
    shortcutsLinkIcon: {
      width: "48px",
      height: "48px",
      "--size ": "70px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-repeat": "no-repeat",
      "-webkit-mask-size": "contain",
      "mask-size ": "contain",
      [theme.breakpoints.down("641")]: {
        width: "43px",
        height: "43px",
      },
      [theme.breakpoints.down("481")]: {
        width: "33px",
        height: "33px",
      },
      [theme.breakpoints.down("381")]: {
        width: "24px",
        height: "24px",
      },
      [theme.breakpoints.down("321")]: {
        width: "22px",
        height: "22px",
      },
    },
    subLabel: {
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    loyaltyInnerWrapper: {
      display: "flex",
      alignItems: "center",
      marginRight: "15px",
      width: "56%",
      gap: "10px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
      [theme.breakpoints.down(350)]: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
    bannerWrapper: {
      // display: "flex",
      // justifyContent: "center",
      padding: "30px 0",
      [theme.breakpoints.down("641")]: {
        padding: "28px 0",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px 0",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px 0",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px 0",
      },
    },
    dotsCss: {
      position: "absolute",
      bottom: "0px",
      right: "10px",
    },
    bannerPanel: {
      height: "100% !important",
      width: "100%",
      position: "relative",
      "& img": {
        height: "100%",
        width: "100%",
        boxShadow: "0px 0px 4px #c3c3c3",
        borderRadius: "0.25rem",
        objectFit: "contain",
        objectPosition: "center",
      },
      [theme.breakpoints.down("641")]: {
        height: "174px !important",
      },
      [theme.breakpoints.down("481")]: {
        height: "134px !important",
      },
      [theme.breakpoints.down("381")]: {
        height: "116px !important",
      },
      [theme.breakpoints.down("321")]: {
        height: "104px !important",
      },
    },
    linkCss: {
      textDecoration: "none",
      color: theme.palette.typography.texts.color,
    },
    availWrapper: {
      width: "100%",
      height: "77px",
      objectFit: "contain",
      borderRadius: "5px",
      backgroundColor: theme.palette.cards.backgroundColor,
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "2",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "30px",
      [theme.breakpoints.down("641")]: {
        height: "60px",
        padding: "24px",
      },
      [theme.breakpoints.down("481")]: {
        height: "44px",
        padding: "20px",
      },
      [theme.breakpoints.down("381")]: {
        height: "36px",
        padding: "7px 13px",
      },
      [theme.breakpoints.down("321")]: {
        height: "32px",
        padding: "7px 12px",
      },
    },
    submitIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${submitIcon})`,
      "mask-image": `url(.${submitIcon})`,
      mask: `url(.${submitIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${submitIcon}) no-repeat center / contain`,
    },
    crossIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${crossIcon})`,
      "mask-image": `url(.${crossIcon})`,
      mask: `url(.${crossIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${crossIcon}) no-repeat center / contain`,
    },
    redeemIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${redeemIcon})`,
      "mask-image": `url(.${redeemIcon})`,
      mask: `url(.${redeemIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${redeemIcon}) no-repeat center / contain`,
    },
    referFriendIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${referFriendIcon})`,
      "mask-image": `url(.${referFriendIcon})`,
      mask: `url(.${referFriendIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${referFriendIcon}) no-repeat center / contain`,
    },
    whatsappIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${whatsappIcon})`,
      "mask-image": `url(.${whatsappIcon})`,
      mask: `url(.${whatsappIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${whatsappIcon}) no-repeat center / contain`,
    },
    feedbackIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${feedbackIcon})`,
      "mask-image": `url(.${feedbackIcon})`,
      mask: `url(.${feedbackIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${feedbackIcon}) no-repeat center / contain`,
    },
    transactionIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${transactionIcon})`,
      "mask-image": `url(.${transactionIcon})`,
    },
    visitMallIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${visitMallIcon})`,
      "mask-image": `url(.${visitMallIcon})`,
    },
    wrapper_height: {
      height: "100%",
      backgroundColor: theme.palette.common.globalBackground,
    },
    modalCss: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    closeBTn: {
      position: "absolute",
      width: "25px",
      height: "25px",
      top: 10,
      right: 10,
      outline: 0,
      [theme.breakpoints.down("641")]: {
        width: "25px",
        height: "25px",
      },
      [theme.breakpoints.down("481")]: {
        width: "25px",
        height: "25px",
      },
      [theme.breakpoints.down("381")]: {
        width: "25px",
        height: "25px",
      },
      [theme.breakpoints.down("321")]: {
        width: "25px",
        height: "25px",
      },
    },
    CloseIconCss: {
      width: "25px",
      height: "25px",
      "--size ": "70px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-repeat": "no-repeat",
      "-webkit-mask-size": "contain",
      "mask-size ": "contain",
      [theme.breakpoints.down("641")]: {
        width: "30px",
        height: "30px",
      },
      [theme.breakpoints.down("481")]: {
        width: "30px",
        height: "30px",
      },
      [theme.breakpoints.down("381")]: {
        width: "24px",
        height: "24px",
      },
      [theme.breakpoints.down("321")]: {
        width: "22px",
        height: "22px",
      },
    },
    imgcss: {
      width: "100%",
      height: "100%",
      alignSelf: "center",
    },
    modalContainer: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
      width: "310px",
      height: "310px",
      outline: 0,
      [theme.breakpoints.down("641")]: {
        width: "310px",
        height: "310px",
      },
      [theme.breakpoints.down("481")]: {
        width: "310px",
        height: "310px",
      },
      [theme.breakpoints.down("381")]: {
        width: "310px",
        height: "310px",
      },
      [theme.breakpoints.down("321")]: {
        width: "310px",
        height: "310px",
      },
    },
    Blue: {
      backgroundColor: "var(--blue)",
    },
    Gold: {
      backgroundColor: "var(--gold)",
    },
    Silver: {
      backgroundColor: "var(--silver)",
    },
    Platinum: {
      color: "#a3a3a3",
      backgroundColor: "var(--platinum)",
    },
  };
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferFriend: "/",
      launchBannerURl: "",
      isLaunchBannerOpen: true,
    };
  }

  componentDidMount() {
    this.versionCheck();
    this.setState(
      {
        merchantID:
          this.props.merchantDetailsResponse &&
          this.props.merchantDetailsResponse.id &&
          this.props.merchantDetailsResponse.id,
        whatsappNumber:
          this.props.merchantDetailsResponse &&
          this.props.merchantDetailsResponse.whatsapp_mobile_number &&
          this.props.merchantDetailsResponse.whatsapp_mobile_number,
        launchBannerURl:
          this.props.merchantDetailsResponse &&
          this.props.merchantDetailsResponse.launch_banner &&
          this.props.merchantDetailsResponse.launch_banner,
      },
      () => {
        this.modalInstruction();
      }
    );
  }
  onFocus = async () => {
    this.setState({
      isLaunchBannerOpen: true,
    });
  };

  modalInstruction = async () => {
    let temp = await getStorageItem("IsTabActive");
    if (temp === "false") {
      this.setState({ isLaunchBannerOpen: false });
    } else {
      this.setState({
        isLaunchBannerOpen: true,
      });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus());
  }

  pointsCalculation = () => {
    let current_points_balance =
      this.props.merchantDetailsResponse &&
      this.props.merchantDetailsResponse.current_points_balance;
    let x_points_equivalent_to =
      this.props.merchantDetailsResponse &&
      this.props.merchantDetailsResponse.x_points_equivalent_to;
    let x_currency =
      this.props.merchantDetailsResponse &&
      this.props.merchantDetailsResponse.x_currency;

    return parseFloat(
      current_points_balance * (x_points_equivalent_to * x_currency)
    ) %
      1 !==
      0
      ? parseFloat(
          current_points_balance * (x_points_equivalent_to * x_currency)
        ).toFixed(2)
      : Math.floor(
          current_points_balance * (x_points_equivalent_to * x_currency)
        );
  };

  thousandSeparator = (amt) =>
    amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  versionCheck = () => {
    const formData = new URLSearchParams();
    formData.append("browser", platform.name);
    formData.append("merchant_id", apiData.merchant_id);
    formData.append(
      "customer_id",
      getStorageItem("user") && JSON.parse(getStorageItem("user")).id
    );
    stockAPI(formData, "POST", "/appVersion").then(async (response) => {
      if (response) {
        if (getStorageItem("currVersion")) {
          const currVersion = getStorageItem("currVersion");
          if (currVersion !== response.app_version) {
            (function (d, meta) {
              meta = d.createElement("meta");
              meta.id = "temp-meta";
              meta.content = "no-cache";
              d.getElementsByTagName("head")[0].appendChild(meta);
            })(document);
            setStorageItem("currVersion", response.app_version);
            window.location.reload(false);
            // if ("serviceWorker" in navigator) {
            //   navigator.serviceWorker
            //     .register("/service-worker.js", { scope: "/" })
            //     .then(function (registration) {
            //       // registration worked
            //       console.log("Registration succeeded. registration.update()");
            //       registration.update().then(function () {
            //         setStorageItem("currVersion", response.app_version);
            //       });
            //       registration.skipWaiting().then(function () {
            //         setStorageItem("currVersion", response.app_version);
            //         window.location.reload();
            //       });
            //     })
            //     .catch(function (error) {
            //       // registration failed
            //       console.log("Registration failed with " + error);
            //     });
            // }
          }
        } else {
          setStorageItem("currVersion", response.app_version);
        }
      }
    });
  };
  redirectToReferFriend() {
    let isReferralBonus =
      this.props.merchantDetailsResponse &&
      this.props.merchantDetailsResponse.is_referral_bonus &&
      this.props.merchantDetailsResponse.is_referral_bonus;

    if (parseInt(isReferralBonus) == 0) {
      if (navigator.share) {
        navigator
          .share({
            text:
              this.props.merchantDetailsResponse &&
              this.props.merchantDetailsResponse.referral_text,
            // url: document.location.href,./
          })
          .then(() => {
            console.log("Successfully shared");
          })
          .catch((error) => {
            console.error("Something went wrong sharing the blog", error);
          });
      }
      // window.open(`${WHATSAPP_SHARING_LINK}?text=${this.props.merchantDetailsResponse && this.props.merchantDetailsResponse.referral_text}`)
    } else {
      this.props.history.push("/refer-friend");
    }
  }

  render() {
    const { classes, banners, merchantDetailsResponse } = this.props;
    const isReferralBonus =
      merchantDetailsResponse &&
      merchantDetailsResponse.is_referral_bonus &&
      merchantDetailsResponse.is_referral_bonus;
    return (
      <div className={classes.wrapper}>
        <div className={classes.wrapper_height}>
          <div className={classes.innerWrap}>
            <div className={classes.loyaltyWrap}>
              <div className={classes.loyaltyInnerWrapper}>
                <div className={classes.loyaltyName}>
                  {this.props.merchantDetailsResponse &&
                    this.props.merchantDetailsResponse.loyalty_program_name}
                </div>
                {this.props.merchantDetailsResponse &&
                  this.props.merchantDetailsResponse.customerDetails &&
                  this.props.merchantDetailsResponse.customerDetails
                    .tier_count &&
                  this.props.merchantDetailsResponse.customerDetails
                    .tier_count !== "1" && (
                    <div
                      className={`${
                        this.props.merchantDetailsResponse &&
                        this.props.merchantDetailsResponse.customerDetails &&
                        this.props.merchantDetailsResponse.customerDetails
                          .current_tier_name
                          ? classes.loyaltyNameInner
                          : ""
                      } ${
                        classes[
                          this.props.merchantDetailsResponse &&
                            this.props.merchantDetailsResponse
                              .customerDetails &&
                            this.props.merchantDetailsResponse.customerDetails
                              .current_tier_name
                        ]
                      }`}
                    >
                      <span className={classes.loyaltyNameInnerLabel}>
                        {this.props.merchantDetailsResponse &&
                          this.props.merchantDetailsResponse.customerDetails &&
                          this.props.merchantDetailsResponse.customerDetails
                            .current_tier_name}
                      </span>
                    </div>
                  )}
              </div>
              <div className={classes.membershipLabel}>
                <Typography className={classes.membershipLabel}>
                  <Link to="/membership">My Membership</Link>
                </Typography>
              </div>
            </div>

            {/* <div className={classes.pointsWrapper}>
              <div className={classes.pointsWrapper1}>
                <div className={classes.avalPoints}>
                  {Math.floor(
                    this.props.merchantDetailsResponse &&
                      this.props.merchantDetailsResponse.current_points_balance
                  )}{" "}
                  {this.props.merchantDetailsResponse &&
                    this.props.merchantDetailsResponse.points_unit_short_name}
                </div>
                <div className={classes.avalPoints1}>Available Points</div>
              </div>
              <div className={classes.pointsWrapper2}>
                <div className={classes.avalPoints}>
                  {this.props.merchantDetailsResponse &&
                    this.props.merchantDetailsResponse
                      .loyalty_currency_code}{" "}
                  {this.thousandSeparator(this.pointsCalculation())}
                </div>
                <div className={classes.avalPoints1}>
                  Value in{" "}
                  {this.props.merchantDetailsResponse &&
                  this.props.merchantDetailsResponse
                    .loyalty_currency_code_words === "INR"
                    ? "Rupees"
                    : "Rupees"}
                </div>
              </div>
            </div> */}
            <div
              className={classes.availWrapper}
              onClick={() => {
                this.props.history.push("/transactions");
              }}
            >
              <div className={classes.avalPoints1}>Available Points</div>
              <div className={classes.avalPoints}>
                {this.props.merchantDetailsResponse &&
                  (parseFloat(
                    this.props.merchantDetailsResponse.current_points_balance
                  ) %
                    1 !==
                  0
                    ? parseFloat(
                        this.props.merchantDetailsResponse
                          .current_points_balance
                      ).toFixed(2)
                    : Math.floor(
                        this.props.merchantDetailsResponse
                          .current_points_balance
                      ))}{" "}
                {this.props.merchantDetailsResponse &&
                  this.props.merchantDetailsResponse.points_unit_short_name}
              </div>
            </div>
          </div>
          <div className={classes.outerWrap}>
            <div className={classes.bannerWrapper}>
              {banners && banners.length > 0 && (
                <a>
                  <Carousal
                    className={classes.bannerPanel}
                    dotsCss={classes.dotsCss}
                    imagesArray={banners}
                    isRedirect
                    alt="offer-banner"
                  />
                </a>
              )}
            </div>
            <div className={classes.multiBox}>
              {getStorageItem("guidelinesFlag") === "1" ? (
                <Grid
                  component={Link}
                  to="/submit-bill"
                  className={classes.shortcutsLinkBox + " " + classes.linkCss}
                >
                  <div>
                    <span
                      className={
                        classes.submitIconCss + " " + classes.shortcutsLinkIcon
                      }
                    ></span>
                    <Typography className={classes.subLabel}>
                      Submit Bills
                    </Typography>
                  </div>
                </Grid>
              ) : (
                <Grid
                  component={Link}
                  to="/bill-upload-guidelines"
                  className={classes.shortcutsLinkBox + " " + classes.linkCss}
                >
                  <div>
                    <span
                      className={
                        classes.submitIconCss + " " + classes.shortcutsLinkIcon
                      }
                    ></span>
                    <Typography className={classes.subLabel}>
                      Submit Bills
                    </Typography>
                  </div>
                </Grid>
              )}
              <Grid
                component={Link}
                to="/my-rewards"
                className={classes.shortcutsLinkBox + " " + classes.linkCss}
              >
                <div>
                  <span
                    className={
                      classes.referFriendIconCss +
                      " " +
                      classes.shortcutsLinkIcon
                    }
                  ></span>
                  <Typography className={classes.subLabel}>
                    My Rewards
                  </Typography>
                </div>
              </Grid>
              <Grid
                component={Link}
                to="/redeem"
                className={classes.shortcutsLinkBox + " " + classes.linkCss}
              >
                <div>
                  <span
                    className={
                      classes.redeemIconCss + " " + classes.shortcutsLinkIcon
                    }
                  ></span>
                  <Typography className={classes.subLabel}>Redeem</Typography>
                </div>
              </Grid>
              {/* {apiData.merchant_id !== 36 ? ( */}
              {/* <Grid
                component={Link}
                to="/transactions"
                className={classes.shortcutsLinkBox + " " + classes.linkCss}
              >
                <div>
                  <span
                    className={
                      classes.transactionIconCss +
                      " " +
                      classes.shortcutsLinkIcon
                    }
                  ></span>
                  <Typography className={classes.subLabel}>
                    Transactions
                  </Typography>
                </div>
              </Grid> */}
              {/* ) : ( */}
              {/* )} */}
            </div>
            <div className={classes.multiBox}>
              {/* <Grid
                style={{ display: apiData.merchant_id == 36 ? "flex" : "none" }}
                component={Link}
                to="/feedback"
                className={classes.shortcutsLinkBox + " " + classes.linkCss}
              >
                <div>
                  <span
                    className={
                      classes.feedbackIconCss + " " + classes.shortcutsLinkIcon
                    }
                  ></span>
                  <Typography className={classes.subLabel}>
                    Feedback
                  </Typography>
                </div>
              </Grid>

              <Grid
                style={{ display: apiData.merchant_id == 36 ? "flex" : "none" }}
                component={Link}
                onClick={() => {
                  if (this.state.whatsappNumber.length > 9 && this.state.whatsappNumber.length < 11) {
                    window.open(`${WHATSAPP_LINK}${this.state.whatsappNumber}?text=HI`);
                  }

                }}
                className={classes.shortcutsLinkBox + " " + classes.linkCss}
              >
                <div>
                  <span
                    className={
                      classes.whatsappIconCss + " " + classes.shortcutsLinkIcon
                    }
                  ></span>
                  <Typography className={classes.subLabel}>
                    Whatsapp
                  </Typography>
                </div>
              </Grid> */}
              <Grid
                component={Link}
                to="/store-locator"
                className={classes.shortcutsLinkBox + " " + classes.linkCss}
              >
                <div>
                  <span
                    className={
                      classes.visitMallIconCss + " " + classes.shortcutsLinkIcon
                    }
                  ></span>
                  <Typography className={classes.subLabel}>
                    Directory
                  </Typography>
                </div>
              </Grid>
              {merchantDetailsResponse &&
              merchantDetailsResponse.is_referral_enabled == "0" ? null : (
                <Grid
                  component={Link}
                  to="/refer-friend"
                  className={classes.shortcutsLinkBox + " " + classes.linkCss}
                >
                  <div
                    onClick={() => {
                      this.redirectToReferFriend();
                    }}
                  >
                    <span
                      className={
                        classes.referFriendIconCss +
                        " " +
                        classes.shortcutsLinkIcon
                      }
                    ></span>
                    <Typography className={classes.subLabel}>
                      Refer & Earn
                    </Typography>
                  </div>
                </Grid>
              )}
            </div>
            {/* {redeem your points component} */}
            <RedeemProductList
              list={
                this.props.productList &&
                this.props.productList.response &&
                this.props.productList.response.products
              }
              showLoader={this.props.showLoader}
              view={true}
              toggleRelatedProducts={this.props.relatedProducts}
            />
          </div>
        </div>

        {this.props.merchantDetailsResponse &&
        this.props.merchantDetailsResponse.launch_banner ? (
          <Modal
            open={this.state.isLaunchBannerOpen}
            className={classes.modalCss}
          >
            <div className={classes.modalContainer}>
              <div className={classes.closeBTn}>
                <span
                  onClick={async () => {
                    this.setState({ isLaunchBannerOpen: false });
                    await setStorageItem("IsTabActive", "false");
                    await setStorageItem(
                      "LaunchBannerURL",
                      this.state.launchBannerURl
                    );
                  }}
                  className={classes.crossIconCss + " " + classes.CloseIconCss}
                ></span>
              </div>
              <img
                className={classes.imgcss}
                src={
                  this.props.merchantDetailsResponse &&
                  this.props.merchantDetailsResponse.launch_banner &&
                  this.props.merchantDetailsResponse.launch_banner
                }
                alt="Banner"
              />
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Dashboard));
