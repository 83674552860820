import React, { Component, Fragment } from "react";
import {
  Typography,
  Grid,
  Divider,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  ListItemAvatar,
  ListItem,
  Paper,
  List,
  MenuItem,
  FormControl,
  Select,
  InputBase,
} from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { styles } from "./VouchersStyles";
import moment from "moment";
import { lightGrey, color } from "../../UI/Theme";
import NoRecordFound from "../../NoRecordFound/NoRecordFound";
import down_arrow from "../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import { openAlertBox } from "../../../common/AlertMessage/AlertMessage";
import { getStorageItem } from "../../../common/localStorage";
import { apiData } from "../../../common/common-types";
import { stockAPI } from "../../../common/axiosInstance";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    fontSize: "20px",
    padding: "10px 24px 10px 32px",
    textAlign: "left",
    backgroundColor: "#fff",
    border: "solid 1px #e4e4e4",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 24px 8px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 24px 6px 16px",
    },
    [theme.breakpoints.down("641")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("481")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("381")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("321")]: {
      fontSize: "12px",
    },
  },
}))(InputBase);

const points = [
  { name: "All Vouchers", val: 1 },
  { name: "Active Vouchers", val: 2 },
  { name: "Expired Vouchers", val: 3 },
  { name: "Privilege Vouchers", val: 4 },
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};

class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTierName: null,
    };
  }
  async componentDidMount() {
    var user;
    let loyaltyId;
    if (getStorageItem("user") && getStorageItem("loyaltyId")) {
      user = JSON.parse(getStorageItem("user"));
      loyaltyId = parseInt(getStorageItem("loyaltyId"));
      const params = new FormData();
      params.append("customer_id", user.id);
      params.append("platform", apiData.platform);
      params.append("loyalty_id", loyaltyId);
      params.append("merchant_id", apiData.merchant_id);

      var status = await stockAPI(
        params,
        "POST",
        "/getLoyaltyProgramTierDetails"
      );

      if (status) {
        await this.setState({
          currentTierName: status.customer_current_tier_name
            ? status.customer_current_tier_name
            : "",
        });
      }
    }
  }
  render() {
    const { classes, logData, classObj, voucherSelection } = this.props;
    return (
      <div className={classes.voucher_container}>
        <FormControl fullWidth className={classes.formControl}>
          <Select
            name="vouchers"
            labelId="vouchers"
            id="vouchers"
            value={voucherSelection}
            onChange={(e) => this.props.handleVoucherFilterChange(e)}
            input={<CustomInput />}
            IconComponent={() => (
              <img
                className={classes.down_icon}
                src={down_arrow}
                alt=""
                width="10"
                height="6"
              />
            )}
            MenuProps={MenuProps}
          >
            {points.map((point) => (
              <MenuItem
                className={classes.menuItem}
                key={point.val}
                value={point.val}
              >
                {point.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {Object.keys(logData).length > 0 ? (
          Object.keys(logData).map((item, index) => {
            return (
              <Grid key={index}>
                <Paper className={classObj.paperContainer} key={index}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={item}
                        classes={{ primary: classObj.listTitleText }}
                      />
                    </ListItem>
                    <Divider variant="middle" />
                    {Object.keys(logData).length > 0 &&
                      logData[item].map((record, number) => {
                        return (
                          <div key={number}>
                            <ListItem>
                              <ListItemAvatar className={classObj.avtartCss}>
                                <Avatar
                                  aria-label="recipe"
                                  className={
                                    classObj.orange + " " + classObj.avtartCss
                                  }
                                >
                                  V
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  record.order_date
                                    ? moment(record.order_date).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"
                                }
                                secondary={
                                  <Fragment>
                                    <span
                                      className={classObj.listSecondaryText}
                                    >
                                      Order #:
                                      {record.order_id
                                        ? ` ${record.order_id}`
                                        : "-"}
                                    </span>
                                    <span className={classObj.listBrandText}>
                                      {record.brand_name
                                        ? record.brand_name
                                        : "-"}
                                    </span>
                                  </Fragment>
                                }
                                classes={{
                                  root: classObj.listItemTextRoot,
                                  primary: classObj.listPrimaryText,
                                }}
                              />

                              {record?.is_privilege?.toString() === "1" ? (
                                <ListItemAvatar
                                  classes={{ root: classes.tierAvatarRoot }}
                                >
                                  <Avatar
                                    variant="square"
                                    className={`${classes.square} ${
                                      classes[this.state.currentTierName]
                                    }`}
                                  >
                                    {"Privilege Voucher"}
                                  </Avatar>
                                </ListItemAvatar>
                              ) : null}
                              {/* <ListItemSecondaryAction
                                className={classObj.listVoucherContainer}
                              >
                                <div className={classes.flexCol}>
                                  {!record.voucher_pin && (
                                    <Fragment>
                                      <div
                                        className={
                                          classes.coupons_box +
                                          " " +
                                          classes.noSelect
                                        }
                                      >
                                        <CopyToClipboard
                                          text={record.voucher_code}
                                          onCopy={() =>
                                            openAlertBox(
                                              "Voucher copied to clipboard",
                                              "success"
                                            )
                                          }
                                        >
                                          <Typography
                                            className={classes.voucherCode}
                                            style={{
                                              color: color,
                                              float: "right",
                                              textAlign: "center",
                                            }}
                                          >
                                            {record.voucher_code}
                                          </Typography>
                                        </CopyToClipboard>
                                      </div>
                                      <Typography
                                        className={classes.dateValidity}
                                        style={{
                                          color: lightGrey,
                                          float: "right",
                                          textAlign: "center",
                                          marginTop: "3px",
                                        }}
                                      >
                                        {record.voucher_expiry_date
                                          ? "Valid till" +
                                            " " +
                                            moment(
                                              record.voucher_expiry_date
                                            ).format("Do MMM' YY")
                                          : "-"}
                                      </Typography>
                                    </Fragment>
                                  )}
                                </div>
                              </ListItemSecondaryAction> */}
                            </ListItem>
                            <div className={classes.voucher_content}>
                              <div
                                className={
                                  classes.voucher_pin_box +
                                  " " +
                                  classes.noSelect
                                }
                              >
                                <div>
                                  <Typography
                                    className={classes.listVoucher}
                                    style={{
                                      color: lightGrey,
                                      float: "left",
                                      textAlign: "left",
                                    }}
                                  >
                                    Voucher #
                                  </Typography>
                                  <CopyToClipboard
                                    text={record.voucher_code}
                                    onCopy={() =>
                                      openAlertBox(
                                        "Voucher copied to clipboard",
                                        "success"
                                      )
                                    }
                                  >
                                    <Typography
                                      className={classes.voucherCode}
                                      style={{
                                        color: color,
                                        float: "left",
                                        textAlign: "left",
                                      }}
                                    >
                                      {record.voucher_code}
                                    </Typography>
                                  </CopyToClipboard>
                                </div>
                                <div>
                                  {record.voucher_pin && (
                                    <CopyToClipboard
                                      text={record.voucher_pin}
                                      onCopy={() => {
                                        openAlertBox(
                                          "Voucher Pin copied to clipboard",
                                          "success"
                                        );
                                      }}
                                    >
                                      <Typography
                                        className={classes.voucherPin}
                                        style={{
                                          float: "right",
                                          textAlign: "right",
                                        }}
                                      >
                                        <p className={classes.pin}>PIN</p>
                                        {record.voucher_pin || "----"}
                                      </Typography>
                                    </CopyToClipboard>
                                  )}
                                </div>
                              </div>
                              <div>
                                <Typography
                                  className={classes.dateValidity}
                                  style={{
                                    color: lightGrey,
                                    float: "right",
                                    textAlign: "center",
                                    marginBottom: "8px",
                                  }}
                                >
                                  {record.voucher_expiry_date
                                    ? "Valid till" +
                                      " " +
                                      moment(record.voucher_expiry_date).format(
                                        "Do MMM' YY"
                                      )
                                    : "-"}
                                </Typography>
                              </div>
                            </div>

                            {number !== logData[item].length - 1 && (
                              <Divider variant="middle" light />
                            )}
                          </div>
                        );
                      })}
                  </List>
                </Paper>
              </Grid>
            );
          })
        ) : (
          <NoRecordFound height="250px" message="No vouchers to display" />
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Vouchers));
